import makeThemedStyles from '../Theme/makeThemedStyles';

const useStyles = makeThemedStyles(theme => ({
  gridItem: {
    display: 'flex',
    flexFlow: 'column nowrap',
    // justifyContent: 'center',
    alignItems: 'center',
  },
  paperPanel: {
    width: '100%',
    marginTop: '-1.5rem',
    // paddingTop: '1.5em',
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    overflow: 'hidden',
    paddingBottom: ({ canExpand }: any) => (canExpand ? '0' : '0.25rem'),
  },

  buttonPanel: {
    minHeight: '2em',
  },

  infoPanel: {
    color: '#888888',
  },

  infoIcon: {
    fontSize: '0.7em',
  },

  expandBtn: {
    margin: 0,
    padding: 0,
    width: '100%',
    borderRadius: 0,
  },

  extraInfo: {
    padding: theme.spacing(0, 1),
    textAlign: 'left',
  },
}));

export default useStyles;
