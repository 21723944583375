import makeThemedStyles from '../Theme/makeThemedStyles';

const useStyles = makeThemedStyles(theme => ({
  domainEdit: {
    padding: '1rem',
  },
  labelItem: {
    margin: theme.spacing(0, 1),
    padding: 0,
  },
}));

export default useStyles;
