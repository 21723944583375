import React from 'react';
import { TextFieldProps, TextField } from '@material-ui/core';
import { FormStateItem } from './useFormState';
import useInputStyles from './inputStyles';

interface Props {
  formItem: FormStateItem;
  label?: string;
  childProps?: TextFieldProps;
}

const Input = ({ formItem, label, childProps }: Props) => {
  const classes = useInputStyles();
  const { value, set } = formItem;
  const onChange = e => set(e.target.value);

  return <TextField value={value} label={label} onChange={onChange} fullWidth className={classes.input} InputLabelProps={{ shrink: true }} {...childProps} />;
};

export default React.memo(Input);
