import React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { FormStateItem } from './useFormState';
import useStyles from './radioBtnStyles';

export interface RadioBtnOption {
  value: any;
  label?: string;
  icon?: any;
  colour?: string;
  constantColour?: string;
  bgColour?: string;
}

interface GroupProps {
  formItem: FormStateItem;
  options: RadioBtnOption[];
}

const RadioBtnGroup = React.memo(({ formItem, options, ...props }: GroupProps) => {
  const { value, set } = formItem;
  const selected = options.find(option => option.value === value);
  const classes = useStyles(selected);
  const onChange = (e, val) => set(val);

  return (
    <BottomNavigation value={value} onChange={onChange} showLabels className={classes.group}>
      {options.map(option => (
        <BottomNavigationAction key={option.value} label={option.label} value={option.value} icon={option.icon} className={classes.item} style={{ color: option.constantColour }} />
      ))}
    </BottomNavigation>
  );
});

export default RadioBtnGroup;
