import React from 'react';
import { Paper, Collapse, IconButton, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import ExpandIcon from './ExpandIcon';
import makeThemedStyles from '../Theme/makeThemedStyles';

const useStyles = makeThemedStyles(theme => ({
  paper: {
    margin: theme.spacing(0.25, 0),
    backgroundColor: grey[200],
    cursor: 'pointer',
  },
  listItem: {
    padding: theme.spacing(0, 1),
  },
  icon: {
    minWidth: 0,
    marginRight: theme.spacing(1),
  },
  button: {
    padding: theme.spacing(1, 0),
  },
  content: {
    marginBottom: theme.spacing(1.5),
  },
}));

interface Props {
  title?: React.ReactNode;
  startIcon?: React.ReactNode;
  open: boolean;
  set(value: boolean);
  children: React.ReactNode;
}

const ExpandGroup = ({ title, startIcon, open, set, children }: Props) => {
  const classes = useStyles();

  const toggle = () => set(!open);

  return (
    <>
      <Paper elevation={2} className={classes.paper} onClick={toggle}>
        <ListItem dense button className={classes.listItem} onClick={toggle}>
          {!!startIcon && <ListItemIcon className={classes.icon}>{startIcon}</ListItemIcon>}
          <ListItemText>{title}</ListItemText>
          <IconButton className={classes.button} onClick={toggle}>
            <ExpandIcon open={open} />
          </IconButton>
        </ListItem>
      </Paper>
      <Collapse in={open}>
        <div className={classes.content}>{children}</div>
      </Collapse>
    </>
  );
};

export default React.memo(ExpandGroup);
