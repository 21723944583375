import React from 'react';
import Domain from '../../components/Domain/Domain';
import { useAppContext } from '../../data/AppContext';
import { useParams } from 'react-router-dom';

const DomainPage = () => {
  const params = useParams();
  const { appData } = useAppContext();

  const domain = appData.domains.find(({ id }) => id === params.domainId) || appData.domains[0];

  return <Domain domain={domain} key={domain.id}></Domain>;
};

export default DomainPage;
