import React from 'react';

const BoyFace = ({ className }) => (
  <svg version="1.1" x="0" y="0" width="1417.323" height="1417.323" viewBox="0, 0, 1417.323, 1417.323" className={className}>
    <g id="face">
      <path
        d="M703.811,216.595 C825.073,211.678 949.989,250.431 1007.811,344.595 C1061.539,432.094 1104.43,530.094 1099.811,652.595 C1094.678,788.734 1062,930 998,1046 C936.355,1157.731 849.225,1215.562 712,1216 C624.099,1216.28 507.077,1169.296 438,1062 C355.594,934 318.862,789.824 315.811,636.595 C312.893,490.094 367.277,396.099 445.539,318.094 C523.801,240.089 617.539,220.094 703.811,216.595 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M708.661,925.661 C785.644,926.269 845.66,986.857 846.631,1063.607 L846.631,1063.607 L846.631,1063.631 C846.631,1160.85 784.86,1239.661 708.661,1239.661 C632.463,1239.661 570.692,1160.85 570.692,1063.631 L570.692,1063.607 L570.692,1063.607 C571.306,986.614 631.915,926.633 708.661,925.661 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M1073.977,824.395 C1038.354,824.395 1009.477,765.072 1009.477,691.895 C1009.477,618.717 1038.354,559.395 1073.977,559.395 C1109.599,559.395 1138.477,618.717 1138.477,691.895 C1138.477,765.072 1109.599,824.395 1073.977,824.395 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M356.5,824.395 C320.878,824.395 292,765.072 292,691.895 C292,618.717 320.878,559.395 356.5,559.395 C392.122,559.395 421,618.717 421,691.895 C421,765.072 392.122,824.395 356.5,824.395 z"
        fill="#FFDBAC"
        id="skin"
      />
    </g>
    <g id="features">
      <path
        d="M709.586,926.708 C709.586,926.708 678.938,932.509 655.766,907.822 C633.099,875.236 663.766,821.912 690.948,733.15"
        fillOpacity="0"
        stroke="#000000"
        strokeWidth="10"
        id="nose"
        opacity="0.2"
      />
      <path d="M602,1030.21 C645.333,1053.544 784.667,1054.878 825.333,1030.21" fillOpacity="0" stroke="#000000" strokeWidth="10" id="mouth" opacity="0.2" />
    </g>
    <g id="hair_layer">
      <path
        d="M615.958,159.022 C633.551,160.004 703.902,210.07 715.829,210.849 C727.756,211.628 827.643,164.823 844.904,167.138 C862.164,169.453 908.719,235.664 923.646,240.646 C938.573,245.628 1016.862,227.767 1029.456,236.082 C1042.049,244.397 1050.436,308.139 1054.03,318.788 C1057.624,329.436 1073.564,363.216 1086.312,395.313 C1103.35,438.208 1105.108,487.453 1105.108,487.453 C1105.108,487.453 1098.734,557.395 1098.734,557.395 C1098.734,557.395 1070.972,658.594 1070.972,658.594 C1070.972,658.594 1016.972,532.594 1016.972,532.594 C1016.972,532.594 933.412,468.641 899.412,430.641 C868.972,436.594 800.972,464.594 734.972,476.594 C673.276,487.811 570.956,502.54 569.273,498.594 C567.591,494.647 625.655,453.54 620.972,448.594 C616.29,443.647 484.714,506.594 458.552,514.594 C432.391,522.594 398.972,550.195 398.972,557.395 C398.972,564.594 342.972,660.594 342.972,660.594 C342.972,660.594 314.281,557.395 314.281,557.395 C314.281,557.395 304.722,511.423 326.73,420.963 C348.737,330.504 385.958,302.689 396.423,285.516 C406.889,268.342 415.858,219.082 431.456,212.182 C442.636,207.236 519.031,222.04 528.958,221.022 C538.885,220.004 598.364,158.04 615.958,159.022 z"
        fill="#9D6214"
        id="hair"
      />
    </g>
  </svg>
);

export default React.memo(BoyFace);
