import React, { useState } from 'react';
import { Grid, Box, Button } from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon, ChevronLeft as LeftIcon, ChevronRight as RightIcon, ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@material-ui/icons';

import makeThemedStyles from '../Theme/makeThemedStyles';
import { Character, LabelTitles, LabelKeys } from '../../data/types';
import { useDomainContext } from '../../data/DomainContext';
import SmallBtn from '../HeadItem/SmallBtn';
import HeadItem from '../HeadItem/HeadItem';
import { cloneCharacters } from '../../utils/clone';
import { labelKeys } from '../../data/values';
import ExpandGroup from '../ExpandGroup/ExpandGroup';
import { getLabelTextStyle } from '../../utils/colours';
import { CircleIcon } from '../Icons/Circle';

const useStyles = makeThemedStyles(theme => ({
  charGrid: {
    marginTop: theme.spacing(0),
  },
  groupRoot: {
    marginTop: theme.spacing(1),
  },
  groupBtn: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '10rem',
    },
  },
}));

interface Props {
  characters: Character[];
  labelTitles: LabelTitles;
  bySwimlane?: boolean;
  edit(character: Character);
  remove(character: Character);
}

const CharacterListFlat = ({ characters, edit, remove }: Props) => {
  const classes = useStyles();
  const { domain, setCharacters } = useDomainContext();

  const { labels } = domain;

  const move = (character: Character, dir: number) => () => {
    const index = characters.indexOf(character);
    const newOrder = cloneCharacters(characters);
    newOrder.splice(index + dir, 0, newOrder.splice(index, 1)[0]);
    setCharacters(newOrder);
  };
  const editHandler = key => () => edit(key);
  const removeHandler = char => () => remove(char);

  return (
    <Grid container spacing={3} className={classes.charGrid}>
      {characters.map((char, index) => (
        <HeadItem
          key={char.id}
          character={char}
          titles={labels}
          leftButtons={
            <>
              <SmallBtn onClick={move(char, -1)} disabled={index === 0} icon={<LeftIcon />} />
              <SmallBtn onClick={move(char, 1)} disabled={index === characters.length - 1} icon={<RightIcon />} />
            </>
          }
          rightButtons={
            <>
              <SmallBtn onClick={removeHandler(char)} icon={<DeleteIcon />} />
              <SmallBtn onClick={editHandler(char)} icon={<EditIcon />} />
            </>
          }
        />
      ))}
    </Grid>
  );
};

interface Swimlane {
  title: string;
  open: boolean;
  label?: LabelKeys | string;
  characters: Character[];
}

const CharacterListSwimlane = ({ characters, labelTitles, edit, remove }: Props) => {
  const classes = useStyles();
  const { domain, setCharacters } = useDomainContext();
  const [openLabels, setOpenLabels] = useState<string[]>([...labelKeys, 'unlabelled']);

  const { labels } = domain;

  const usedLabels = (() => {
    const all = characters.flatMap(({ labels }) => labels);
    return labelKeys.filter(key => all.includes(key));
  })();

  const swimlanes: Swimlane[] = usedLabels.map(key => ({
    title: labelTitles[key],
    open: openLabels.includes(key),
    label: key,
    characters: characters.filter(({ labels }) => labels.includes(key)),
  }));
  (() => {
    const unlabelled = characters.filter(({ labels }) => labels.length === 0);
    if (unlabelled.length) {
      swimlanes.push({
        title: 'Unlabelled',
        open: openLabels.includes('unlabelled'),
        label: 'unlabelled',
        characters: unlabelled,
      });
    }
  })();

  const move = (origChar: Character, swimlane: Swimlane, dir: number) => () => {
    const replChar = swimlane.characters[swimlane.characters.indexOf(origChar) + dir];
    const origIndex = characters.indexOf(origChar);
    const replIndex = characters.indexOf(replChar);

    const newOrder = cloneCharacters(characters);
    const temp = newOrder[origIndex];
    newOrder[origIndex] = newOrder[replIndex];
    newOrder[replIndex] = temp;
    setCharacters(newOrder);
  };

  const editHandler = key => () => edit(key);
  const removeHandler = char => () => remove(char);
  const setLabelOpen = key => open => {
    if (open && !openLabels.includes(key)) {
      setOpenLabels([...openLabels, key]);
    }
    if (!open && openLabels.includes(key)) {
      const newOpened = [...openLabels];
      newOpened.splice(newOpened.indexOf(key), 1);
      setOpenLabels(newOpened);
    }
  };
  const expandHandler = () => setOpenLabels([...labelKeys, 'unlabelled']);
  const collapseHandler = () => setOpenLabels([]);

  return (
    <div className={classes.groupRoot}>
      <Box display="flex" justifyContent="flex-end">
        <Button endIcon={<ExpandMoreIcon />} onClick={expandHandler} children="Expand All" className={classes.groupBtn} />
        <Button endIcon={<ExpandLessIcon />} onClick={collapseHandler} children="Collapse All" className={classes.groupBtn} />
      </Box>

      {swimlanes.map(swimlane => (
        <ExpandGroup key={swimlane.label || '_'} title={swimlane.title} startIcon={<CircleIcon style={getLabelTextStyle(swimlane.label)} />} open={swimlane.open} set={setLabelOpen(swimlane.label)}>
          <Grid container spacing={1} className={classes.charGrid}>
            {swimlane.characters.map((char, index) => (
              <HeadItem
                key={char.id}
                character={char}
                titles={labels}
                leftButtons={
                  <>
                    <SmallBtn onClick={move(char, swimlane, -1)} disabled={index === 0} icon={<LeftIcon />} />
                    <SmallBtn onClick={move(char, swimlane, 1)} disabled={index === swimlane.characters.length - 1} icon={<RightIcon />} />
                  </>
                }
                rightButtons={
                  <>
                    <SmallBtn onClick={removeHandler(char)} icon={<DeleteIcon />} />
                    <SmallBtn onClick={editHandler(char)} icon={<EditIcon />} />
                  </>
                }
              />
            ))}
          </Grid>
        </ExpandGroup>
      ))}
    </div>
  );
};

const CharacterList = (props: Props) => (props.bySwimlane ? <CharacterListSwimlane {...props} /> : <CharacterListFlat {...props} />);

export default React.memo(CharacterList);
