import React from 'react';
import { IconButton, IconButtonProps } from '@material-ui/core';
import makeThemedStyles from '../Theme/makeThemedStyles';

const useStyles = makeThemedStyles(theme => ({
  smallBtn: {
    '& .MuiSvgIcon-root': {
      // fontSize: '1.25rem',
      fontSize: ({ smSize }: any) =>
        ({
          xs: '0.75rem',
          sm: '1rem',
          md: '1.25rem',
          lg: '1.5rem',
        }[smSize]),
    },
  },
}));

interface Props extends IconButtonProps {
  icon?: React.ReactNode;
  smSize?: 'xs' | 'sm' | 'md' | 'lg';
}

const SmallBtn = ({ icon, smSize, ...btnProps }: Props) => {
  const classes = useStyles({ smSize: smSize || 'md' });
  return (
    <IconButton size="small" className={classes.smallBtn} {...btnProps}>
      {icon}
    </IconButton>
  );
};

export default React.memo(SmallBtn);
