import React, { useState } from 'react';
import { Button, ButtonProps, Fab } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

import { getEmptyCharacter } from '../../data/empty';

import ResponsiveDialog from '../ResponsiveDialog/ResponsiveDialog';
import CharacterEdit from './CharacterEdit';
import makeThemedStyles from '../Theme/makeThemedStyles';
import { clj } from '../../utils/reactUtils';

const useStyles = makeThemedStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1,
  },
  fabSpacer: {
    background: 'red',
    marginBottom: theme.spacing(7), // so you can see bottom row headItem without Fab covering it
  },
  hideMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  showMobile: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

interface Props extends ButtonProps {
  btnText?: string;
  btnType?: 'button' | 'fab';
}

const NewCharacterBtn = ({ btnType, btnText, ...btnProps }: Props) => {
  const classes = useStyles();
  const [editOpen, setEditOpen] = useState<boolean>(false);

  let type: any = btnType || 'button';

  const openEdit = () => setEditOpen(true);
  const onEditClose = () => setEditOpen(false);

  return (
    <>
      {type === 'button' && (
        <Button startIcon={<AddIcon />} onClick={openEdit} className={classes.hideMobile} {...btnProps}>
          {btnText}
        </Button>
      )}
      {type === 'fab' && (
        <>
          <Fab onClick={openEdit} children={<AddIcon />} color="primary" className={clj(classes.fab, classes.showMobile)} />
          <div className={clj(classes.fabSpacer, classes.showMobile)} />
        </>
      )}
      <ResponsiveDialog open={editOpen} onClose={onEditClose} maxWidth="md">
        <CharacterEdit character={getEmptyCharacter()} close={onEditClose} />
      </ResponsiveDialog>
    </>
  );
};

export default React.memo(NewCharacterBtn);
