import { skinColours, hairColours, genderColours } from '../../utils/colours';
import makeThemedStyles from '../Theme/makeThemedStyles';

const useStyles = makeThemedStyles(theme => ({
  paper: {
    display: 'inline-flex',
    borderRadius: '50%',
    backgroundColor: '#EEEEEE',
    zIndex: 1,

    '& > *': {
      marginTop: '0.1em',
      marginBottom: '-0.1em',
    },
  },
  faceSVG: {
    width: '5em',
    height: '5em',

    '& #skin': {
      fill: props => skinColours[props.ethnicity],
    },
    '& #hair': {
      fill: props => hairColours[props.hairColour],
    },
  },
  genderSVG: {
    fill: (props: any) => genderColours[props.gender]?.colour,
  },
}));

export default useStyles;
