import React, { useState } from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';

import { useAppContext } from '../../data/AppContext';
import { Drawer, List, Typography, IconButton, Toolbar, AppBar, CssBaseline } from '@material-ui/core';
import ListItemLink from '../../components/ListItemLink/ListItemLink';
import { Menu as MenuIcon, Home as HomeIcon, Book as MUBookIcon } from '@material-ui/icons';

import HomePage from '../HomePage/HomePage';
import DomainsPage from '../DomainsPage/DomainsPage';
import DomainPage from '../DomainPage/DomainPage';
import useStyles from './styles';
import { BookIcon } from '../../components/Icons/Book';
import HistoryLogger from './HistoryLogger';

const Root = () => {
  const classes = useStyles();
  const { appData } = useAppContext();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const toggle = () => setDrawerOpen(!drawerOpen);
  const close = () => setDrawerOpen(false);

  return (
    <div className={classes.root}>
      <Router>
        <HistoryLogger />
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={toggle}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6">Bad with Names</Typography>
          </Toolbar>
        </AppBar>
        <Drawer open={drawerOpen} onClose={close} anchor="left" className={classes.drawer}>
          <Toolbar />
          <div className={classes.drawerContainer}>
            <List>
              <ListItemLink to="/" primary="Home" icon={<HomeIcon />} onClick={close} />
              <ListItemLink to="/domains" primary="Domains" icon={<MUBookIcon />} onClick={close} />

              <div className={classes.nested}>
                {appData.domains.map(({ id, title, subtitle }, index) => (
                  <ListItemLink key={index} to={`/domain/${id}`} primary={title || id} secondary={subtitle} icon={<BookIcon />} onClick={close} />
                ))}
              </div>
            </List>
          </div>
        </Drawer>

        <main className={classes.content}>
          <Toolbar />
          <Switch>
            <Route path="/domain/:domainId">
              <DomainPage />
            </Route>
            <Route path="/domains">
              <DomainsPage />
            </Route>
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
        </main>
      </Router>
    </div>
  );
};

export default Root;
