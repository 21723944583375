import React from 'react';

const OldWomanFace = ({ className }) => (
  <svg version="1.1" x="0" y="0" width="1417.323" height="1417.323" viewBox="0, 0, 1417.323, 1417.323" className={className}>
    <defs>
      <radialGradient id="Gradient_1" gradientUnits="userSpaceOnUse" cx="865.32" cy="893.81" r="102">
        <stop offset="0" stopColor="#000000" />
        <stop offset="1" stopColor="#000000" stopOpacity="0" />
      </radialGradient>
      <radialGradient id="Gradient_2" gradientUnits="userSpaceOnUse" cx="541.32" cy="893.81" r="102">
        <stop offset="0" stopColor="#000000" />
        <stop offset="1" stopColor="#000000" stopOpacity="0" />
      </radialGradient>
    </defs>
    <g id="hair_behind">
      <path
        d="M1150.336,1130.085 C1050.336,1214.205 340.336,1220.183 280.336,1150.062 C223.444,1083.575 328.336,944.085 292,722.116 C255.812,501.053 571.223,436.116 724.662,436.116 C1088.258,523.992 1188.727,513.989 1138.477,724.085 C1086.336,942.085 1250.322,1045.977 1150.336,1130.085 z"
        fill="#9D6214"
        id="hair"
      />
    </g>
    <g id="face">
      <path
        d="M703.811,216.595 C825.073,211.678 949.989,250.43 1007.811,344.595 C1061.539,432.094 1104.43,530.094 1099.811,652.595 C1094.678,788.734 1031.32,964.062 967.32,1080.062 C905.675,1191.794 870.336,1222.062 708.661,1220.094 C550.335,1218.166 525.413,1185.358 456.336,1078.062 C373.93,950.062 318.862,789.824 315.811,636.595 C312.893,490.094 367.277,396.098 445.539,318.094 C523.801,240.089 617.539,220.094 703.811,216.595 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M708.661,925.661 C785.644,926.269 845.66,986.857 846.631,1063.607 L846.631,1063.607 L846.631,1063.631 C846.631,1160.85 784.86,1239.661 708.661,1239.661 C632.463,1239.661 570.692,1160.85 570.692,1063.631 L570.692,1063.607 L570.692,1063.607 C571.306,986.614 631.915,926.633 708.661,925.661 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M1073.977,824.395 C1038.354,824.395 1009.477,765.072 1009.477,691.895 C1009.477,618.717 1038.354,559.395 1073.977,559.395 C1109.599,559.395 1138.477,618.717 1138.477,691.895 C1138.477,765.072 1109.599,824.395 1073.977,824.395 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M356.5,824.395 C320.878,824.395 292,765.072 292,691.895 C292,618.717 320.878,559.395 356.5,559.395 C392.122,559.395 421,618.717 421,691.895 C421,765.072 392.122,824.395 356.5,824.395 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M865.32,995.81 C808.987,995.81 763.32,950.143 763.32,893.81 C763.32,837.477 808.987,791.81 865.32,791.81 C921.653,791.81 967.32,837.477 967.32,893.81 C967.32,950.143 921.653,995.81 865.32,995.81 z"
        fill="url(#Gradient_1)"
        id="cheeks"
        opacity="0.05"
      />
      <path
        d="M541.32,995.81 C484.987,995.81 439.32,950.143 439.32,893.81 C439.32,837.477 484.987,791.81 541.32,791.81 C597.653,791.81 643.32,837.477 643.32,893.81 C643.32,950.143 597.653,995.81 541.32,995.81 z"
        fill="url(#Gradient_2)"
        id="cheeks"
        opacity="0.05"
      />
    </g>
    <g id="features">
      <path d="M647.874,1039.086 L759.766,1039.086" fillOpacity="0" stroke="#000000" strokeWidth="10" id="mouth" opacity="0.2" />
      <path d="M709.586,926.5 C709.586,926.5 660.375,934.449 647.874,911.75 C631.306,881.667 666.5,836.5 690.948,665.15" fillOpacity="0" stroke="#000000" strokeWidth="10" id="nose" opacity="0.2" />
      <path d="M774.454,1063.5 C794.121,1055.5 794.788,1030.167 776.121,1015.167" fillOpacity="0" stroke="#000000" strokeWidth="10" id="wrinkle" opacity="0.2" />
      <path d="M634.108,1063.5 C614.441,1055.5 613.775,1030.167 632.441,1015.167" fillOpacity="0" stroke="#000000" strokeWidth="10" id="wrinkle" opacity="0.2" />
      <path d="M923.482,962.643 C960.815,933.977 962.815,846.643 917.482,825.977" fillOpacity="0" stroke="#000000" strokeWidth="5" id="sunken_cheek" opacity="0.05" />
      <path d="M494.5,961.643 C457.167,932.977 455.167,845.643 500.5,824.977" fillOpacity="0" stroke="#000000" strokeWidth="5" id="sunken_cheek" opacity="0.05" />
      <path d="M618.246,393.892 C704.246,407.892 762.108,407.892 804.246,401.225" fillOpacity="0" stroke="#000000" strokeWidth="5" id="wrinkle" opacity="0.05" />
      <path d="M541.668,413.579 C609.823,439.951 829.668,447.579 883.668,415.579" fillOpacity="0" stroke="#000000" strokeWidth="5" id="wrinkle" opacity="0.05" />
      <path d="M580.246,457.225 C652.913,477.225 775.579,481.225 839.579,460.559" fillOpacity="0" stroke="#000000" strokeWidth="5" id="wrinkle" opacity="0.05" />
    </g>
    <g id="hair_layer">
      <path
        d="M928.776,390.05 C741.31,413.358 655.745,345.178 571.666,393.67 C487.586,442.162 469.794,493.849 421.974,534.341 C374.154,574.833 348.595,763.558 328.775,768.05 C308.955,772.542 294.156,702.062 278.336,654.062 C262.516,606.063 207.31,468.341 253.31,370.341 C299.31,272.341 353.227,189.156 513.31,108.341 C660.187,34.193 941.31,78.341 987.31,174.341 C1053.31,176.341 1146.147,277.178 1184.327,393.67 C1222.507,510.163 1172.156,595.57 1158.336,650.062 C1144.516,704.555 1130.82,775.557 1108.888,778.05 C1086.955,780.542 1036.595,657.558 1020.776,604.05 C1004.956,550.542 970.608,384.849 928.776,390.05 z"
        fill="#9D6214"
        id="hair"
      />
    </g>
  </svg>
);

export default React.memo(OldWomanFace);
