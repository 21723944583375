import { RadioBtnOption } from './RadioBtn';
import makeThemedStyles from '../Theme/makeThemedStyles';

const useStyles = makeThemedStyles(theme => ({
  group: {
    paddingBottom: '0.5rem',
  },
  item: {
    minWidth: 'unset',
    maxWidth: 'unset',
    '&.Mui-selected': {
      transition: 'background-color 0s',
      transitionDelay: '0.05s',
      color: (option: RadioBtnOption) => option.colour,
      backgroundColor: (option: RadioBtnOption) => option.bgColour,
    },
    '& .MuiBottomNavigationAction-label': {
      fontSize: '0.875rem',
    },
    '& .Mui-selected': {
      fontSize: '0.875rem',
    },
  },
}));

export default useStyles;
