import React from 'react';

const ManFace = ({ className }) => (
  <svg version="1.1" x="0" y="0" width="1417.323" height="1417.323" viewBox="0, 0, 1417.323, 1417.323" className={className}>
    <g id="face">
      <path
        d="M703.811,216.595 C825.169,217.554 949.246,250.891 1007.811,344.595 C1087.811,472.595 1108.045,512.609 1099.811,652.595 C1091.811,788.595 1048.866,888.325 988,1006 C928,1122 906,1236 709.406,1238.531 C497.386,1241.261 505.257,1128.936 436.18,1021.641 C353.773,893.641 315.811,789.854 315.811,636.595 C324.778,435.221 373.549,402.6 451.811,324.595 C530.072,246.591 582.826,218.126 703.811,216.595 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M708.661,947.661 C785.644,948.269 845.66,1008.857 846.631,1085.607 L846.631,1085.607 L846.631,1085.631 C846.631,1182.85 784.86,1261.661 708.661,1261.661 C632.463,1261.661 570.692,1182.85 570.692,1085.631 L570.692,1085.607 L570.692,1085.607 C571.306,1008.614 631.915,948.633 708.661,947.661 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M1073.977,824.395 C1038.354,824.395 1009.477,765.072 1009.477,691.895 C1009.477,618.717 1038.354,559.395 1073.977,559.395 C1109.599,559.395 1138.477,618.717 1138.477,691.895 C1138.477,765.072 1109.599,824.395 1073.977,824.395 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M356.5,824.395 C320.878,824.395 292,765.072 292,691.895 C292,618.717 320.878,559.395 356.5,559.395 C392.122,559.395 421,618.717 421,691.895 C421,765.072 392.122,824.395 356.5,824.395 z"
        fill="#FFDBAC"
        id="skin"
      />
    </g>
    <g id="features">
      <path d="M608.161,1039.086 L810.161,1039.086" fillOpacity="0" stroke="#000000" strokeWidth="10" id="mouth" opacity="0.2" />
      <path d="M709.161,948.161 C624.938,948.161 626.938,927.086 690.938,665.086" fillOpacity="0" stroke="#000000" strokeWidth="10" id="nose" opacity="0.2" />
      <path d="M678.938,1156.086 C692.959,1143.659 713.159,1143.859 725.161,1156.086" fillOpacity="0" stroke="#000000" strokeWidth="10" id="dimple" opacity="0.2" />
    </g>
    <g id="hair_layer">
      <path
        d="M723.815,117.495 C774.008,116.161 826.104,128.161 876.153,152.161 C926.201,176.161 974.201,212.161 1016.201,258.828 C1069.294,317.821 1091.509,402.064 1099.902,480.901 C1108.294,559.738 1102.864,633.167 1100.666,670.531 C1096.666,738.531 1089.457,776.688 1080.848,779.267 C1072.239,781.846 1062.23,748.846 1052.63,674.531 C1045.194,616.963 1024.476,544.524 994.413,489.784 C964.351,435.044 954.281,412.411 920.948,376.411 C906.281,396.411 872.136,483.485 784.948,481.745 C697.76,480.004 499.615,381.745 471.615,351.078 C451.615,385.745 437.262,439.729 410.236,492.859 C383.211,545.989 365.544,612.322 369.758,658.109 C378.961,758.109 366.767,798.085 351.519,792.626 C336.272,787.167 317.972,736.272 314.965,654.531 C311.695,565.654 311.247,484.27 321.523,414.411 C331.8,344.553 370.281,256.411 426.281,243.078 C430.281,185.745 508.843,149.289 564.475,133.969 C620.108,118.648 673.623,118.828 723.815,117.495 z"
        fill="#9D6214"
        id="hair"
      />
    </g>
  </svg>
);

export default React.memo(ManFace);
