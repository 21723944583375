import React from 'react';
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { Link } from 'react-router-dom';

interface Props {
  icon?: React.ReactNode;
  primary?: string;
  secondary?: string;
  to?: string;
  onClick?();
  children?: any;
}

const ListItemLink = ({ icon, primary, secondary, to, onClick, children }: Props) => {
  const renderLink = React.useMemo(() => React.forwardRef((itemProps, ref) => <Link to={to} ref={ref} {...itemProps} onClick={onClick} />), [to, onClick]);

  return (
    <ListItem button component={renderLink}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={primary} secondary={secondary} />
      {children}
    </ListItem>
  );
};

export default ListItemLink;
