import { useState } from 'react';

export interface FormStateItem {
  value: any;
  set: Function;
  setTo: Function;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

export interface FormState {
  [key: string]: FormStateItem;
}

export const useFormState = (keys: string[]) => (initialObj: object): FormState => {
  const result = {};
  for (let key of keys) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    result[key] = useFormItemState(initialObj[key]);
  }
  return result;
};

export const convertFormStateToObject = form =>
  Object.keys(form)
    .map(key => [key, form[key].value])
    .reduce((acc, [key, val]) => {
      acc[key] = val;
      return acc;
    }, {});

export const useFormItemState = (initial?: any): FormStateItem => {
  const [value, set] = useState(initial);
  const onChange = e => set(e.currentTarget.value);
  const setTo = value => () => set(value);
  return { value, set, setTo, onChange };
};
