import React from 'react';
import { Check as CheckIcon, Cancel as CancelIcon, Edit as EditIcon } from '@material-ui/icons';
import { Box, Chip, Popper, Grow, Paper, Checkbox, List, ListItem, ClickAwayListener, Icon, Typography, IconButton } from '@material-ui/core';

import { labelKeys } from '../../data/values';
import { LabelTitles } from '../../data/types';

import { FormStateItem } from '../Form/useFormState';
import makeThemedStyles from '../Theme/makeThemedStyles';
import { getLabelStyle } from '../../utils/colours';

const useStyles = makeThemedStyles(theme => ({
  caption: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  container: {
    minHeight: '2em',
    marginBottom: theme.spacing(0.5),
  },
  btnBox: {
    paddingRight: theme.spacing(0.5),
  },
  editBtn: {
    minWidth: 0,
  },
  popper: {
    zIndex: 5000,
  },
  popperPaper: {
    padding: theme.spacing(1),
    minWidth: '15rem',
  },
  list: {
    padding: 0,
  },
  labelItem: {
    margin: theme.spacing(0),
    padding: theme.spacing(0.25, 0),
  },
  chip: {
    margin: theme.spacing(0.1, 0.3, 0.1, 0),
    '& > chip': {
      minWidth: '2rem',
    },
  },
  fullChip: {
    width: '100%',
    justifyContent: 'flex-start',
  },
}));

interface Props {
  formItem: FormStateItem;
  titles: LabelTitles;
}

const LabelEditor = ({ formItem, titles }) => {
  const classes = useStyles();
  const { value, set } = formItem;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const togglePopper = (event: React.MouseEvent): void => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const closePopper = () => setAnchorEl(null);

  const popper = anchorEl ? 'label-popper' : undefined;

  const addLabel = key => () => set([...value, key]);
  const removeLabel = key => () => {
    const newValue = [...value];
    newValue.splice(newValue.indexOf(key), 1);
    set(newValue);
  };

  const toggleLabel = key => () => (value.includes(key) ? removeLabel(key)() : addLabel(key)());

  return (
    <>
      <Typography variant="caption" children="Labels" className={classes.caption} />
      <Box display="flex" justifyContent="flex-start" alignItems="center" alignContent="space-around" flexGrow={1} className={classes.container}>
        <ClickAwayListener onClickAway={closePopper}>
          <Box className={classes.btnBox}>
            <IconButton aria-describedby={popper} size="small" children={<EditIcon />} onClick={togglePopper} className={classes.editBtn} />
            <Popper id={popper} open={!!anchorEl} anchorEl={anchorEl} transition placement="bottom-start" className={classes.popper}>
              {({ TransitionProps }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: 'top left' }} timeout={150}>
                  <Paper className={classes.popperPaper}>
                    <List className={classes.list}>
                      {labelKeys.map(key => (
                        <ListItem key={key} className={classes.labelItem}>
                          <Chip
                            key={key}
                            label={titles[key]}
                            style={getLabelStyle()(key)}
                            className={[classes.chip, classes.fullChip].join(' ')}
                            clickable={true}
                            onClick={toggleLabel(key)}
                            avatar={<Checkbox checked={value.includes(key)} size="small" icon={<Icon />} checkedIcon={<CheckIcon />} style={getLabelStyle(false)(key)} onChange={toggleLabel(key)} />}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </ClickAwayListener>
        <Box display="flex" flexWrap="wrap" flexGrow={1}>
          {value.map(key => (
            <Chip key={key} size="small" label={titles[key]} deleteIcon={<CancelIcon />} onDelete={removeLabel(key)} style={getLabelStyle()(key)} className={classes.chip} />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default React.memo(LabelEditor);
