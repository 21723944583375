import { v4 as uuid } from 'uuid';

import { GENDER, AGE_RANGE, ETHNICITY, HAIR_COLOUR, AppData, Domain, LabelTitles, Character } from './types';
import { CURRENT_SAVE_FORMAT_VERSION } from './values';

export const getEmptySaveFormat = (): AppData => ({
  version: CURRENT_SAVE_FORMAT_VERSION,
  domains: [getEmptyDomain(uuid())],
});

export const getEmptyDomain = (id = uuid()): Domain => ({
  id,
  title: '',
  subtitle: '',
  labels: getEmptyLabelTitles(),
  characters: [],
});
export const getEmptyLabelTitles = (): LabelTitles => ({
  green: '',
  yellow: '',
  orange: '',
  red: '',
  purple: '',
  blue: '',
});
export const getEmptyCharacter = (id = uuid()): Character => ({
  id,
  fullName: '',
  desc: '',
  labels: [],
  gender: GENDER.UNKNOWN,
  age: AGE_RANGE.UNKNOWN,
  ethnicity: ETHNICITY.WHITE,
  hairColour: HAIR_COLOUR.BRUNETTE,
  connections: [],
  notes: '',
});
