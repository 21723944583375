import React from 'react';

const BabyFace = ({ className }) => (
  <svg version="1.1" x="0" y="0" width="1417.323" height="1417.323" viewBox="0, 0, 1417.323, 1417.323" className={className}>
    <defs>
      <radialGradient id="Gradient_1" gradientUnits="userSpaceOnUse" cx="497" cy="824.801" r="102">
        <stop offset="0" stopColor="#FF0000" />
        <stop offset="1" stopColor="#FF0000" stopOpacity="0" />
      </radialGradient>
      <radialGradient id="Gradient_2" gradientUnits="userSpaceOnUse" cx="886" cy="824.801" r="102">
        <stop offset="0" stopColor="#FF0000" />
        <stop offset="1" stopColor="#FF0000" stopOpacity="0" />
      </radialGradient>
    </defs>
    <g id="face">
      <path
        d="M703.811,216.595 C825.073,211.678 950.655,259.835 1008.477,354 C1062.205,441.498 1104.43,530.094 1099.811,652.595 C1094.678,788.734 1076.577,937.765 1009.477,1052 C957.883,1139.836 846.311,1201.398 709.086,1201.836 C621.185,1202.116 479.077,1159.296 410,1052 C327.594,924 318.862,789.824 315.811,636.595 C312.893,490.094 367.277,396.098 445.539,318.094 C523.801,240.089 617.539,220.094 703.811,216.595 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M1072.977,786.395 C1037.354,786.395 1008.477,744.085 1008.477,691.895 C1008.477,639.704 1037.354,597.395 1072.977,597.395 C1108.599,597.395 1137.477,639.704 1137.477,691.895 C1137.477,744.085 1108.599,786.395 1072.977,786.395 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M330.5,786.395 C294.878,786.395 266,744.085 266,691.895 C266,639.704 294.878,597.395 330.5,597.395 C366.122,597.395 395,639.704 395,691.895 C395,744.085 366.122,786.395 330.5,786.395 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M497,926.801 C440.667,926.801 395,881.134 395,824.801 C395,768.468 440.667,722.801 497,722.801 C553.333,722.801 599,768.468 599,824.801 C599,881.134 553.333,926.801 497,926.801 z"
        fill="url(#Gradient_1)"
        id="cheeks"
        opacity="0.1"
      />
      <path
        d="M886,926.801 C829.667,926.801 784,881.134 784,824.801 C784,768.468 829.667,722.801 886,722.801 C942.333,722.801 988,768.468 988,824.801 C988,881.134 942.333,926.801 886,926.801 z"
        fill="url(#Gradient_2)"
        id="cheeks"
        opacity="0.1"
      />
    </g>
    <g id="features">
      <path
        d="M709.586,926.812 C709.586,926.812 678.938,931.597 655.766,911.233 C633.099,884.353 663.766,840.368 690.948,767.15"
        fillOpacity="0"
        stroke="#000000"
        strokeWidth="10"
        id="nose"
        opacity="0.2"
      />
      <path
        d="M617.966,1017.774 C625.466,1017.274 707.593,1015.359 707.593,1015.359 C707.593,1015.359 793.966,1013.774 800.966,1013.774 C807.966,1013.773 808.376,1020.703 804.466,1024.773 C800.556,1028.844 756.57,1074.446 708.018,1075.86 C659.466,1077.274 610.486,1034.07 609.226,1026.922 C607.966,1019.774 610.467,1018.274 617.966,1017.774 z"
        fill="#000000"
        id="mouth"
        opacity="0.2"
      />
      <path
        d="M712.138,364.051 C769.138,310.051 723.346,242.297 693.348,244.051 C663.35,245.805 659.401,267.051 658.874,288.051 C658.348,309.051 683.348,333.051 702.348,319.051 C721.348,305.051 702.304,273.987 690.348,275.051"
        fillOpacity="0"
        stroke="#000000"
        strokeWidth="5"
        id="hair_detail"
        opacity="0.1"
      />
    </g>
  </svg>
);

export default React.memo(BabyFace);
