import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DialogProps, useTheme, Dialog, Toolbar, Paper } from '@material-ui/core';
import makeThemedStyles from '../Theme/makeThemedStyles';

const useStyles = makeThemedStyles(theme => ({
  fullscreen: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
    zIndex: 4900,
  },
}));

interface Props extends DialogProps {}

const ResponsiveDialog = ({ children, open, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  if (fullScreen) {
    return (
      open && (
        <Paper className={classes.fullscreen}>
          {fullScreen && <Toolbar />}
          {children}
        </Paper>
      )
    );
  }

  return (
    <Dialog open={open} {...props}>
      {children}
    </Dialog>
  );
};

export default ResponsiveDialog;
