import React from 'react';
import { FormStateItem } from '../Form/useFormState';
import RadioBtnGroup, { RadioBtnOption } from '../Form/RadioBtn';
import { ETHNICITY } from '../../data/types';
import { CircleIcon } from '../Icons/Circle';
import { skinColours } from '../../utils/colours';
import { Typography } from '@material-ui/core';
import makeThemedStyles from '../Theme/makeThemedStyles';

const useStyles = makeThemedStyles(theme => ({
  container: {
    marginBottom: theme.spacing(0.25),
  },
}));

interface Props {
  formItem: FormStateItem;
}

const options: RadioBtnOption[] = [ETHNICITY.WHITE, ETHNICITY.MIXED_1, ETHNICITY.MIXED_2, ETHNICITY.MIXED_3, ETHNICITY.BLACK].map(value => ({
  value,
  icon: <CircleIcon />,
  constantColour: skinColours[value],
  bgColour: 'rgba(0, 0, 0, 0.1)',
}));

const EthnicityRadio = ({ formItem }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="caption">Ethnicity</Typography>
      <RadioBtnGroup formItem={formItem} options={options} />
    </div>
  );
};

export default React.memo(EthnicityRadio);
