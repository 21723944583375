import React from 'react';

const MAWomanFace = ({ className }) => (
  <svg version="1.1" x="0" y="0" width="1417.323" height="1417.323" viewBox="0, 0, 1417.323, 1417.323" className={className}>
    <g id="hair_behind">
      <path
        d="M1100.152,1149.202 C1038.152,1197.202 457.294,1179.49 355.294,1155.49 C253.294,1131.49 290.461,952.094 292,728.094 C293.539,504.094 571.223,442.094 724.662,442.094 C1088.258,529.97 1135.414,508.094 1138.477,724.094 C1141.539,940.094 1162.152,1101.202 1100.152,1149.202 z"
        fill="#9D6214"
        id="hair"
      />
    </g>
    <g id="face">
      <path
        d="M703.811,216.595 C825.073,211.678 949.989,250.43 1007.811,344.595 C1061.539,432.094 1104.43,530.094 1099.811,652.595 C1094.678,788.734 1048.132,957.741 984.132,1073.741 C922.487,1185.473 877.229,1219.946 708.661,1220.094 C540.795,1220.241 510.836,1182.609 441.759,1075.313 C359.353,947.313 318.862,789.824 315.811,636.595 C312.893,490.094 367.277,396.098 445.539,318.094 C523.801,240.089 617.539,220.094 703.811,216.595 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M708.661,925.661 C785.644,926.269 845.66,986.857 846.631,1063.607 L846.631,1063.607 L846.631,1063.631 C846.631,1160.85 784.86,1239.661 708.661,1239.661 C632.463,1239.661 570.692,1160.85 570.692,1063.631 L570.692,1063.607 L570.692,1063.607 C571.306,986.614 631.915,926.633 708.661,925.661 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M1073.977,824.395 C1038.354,824.395 1009.477,765.072 1009.477,691.895 C1009.477,618.717 1038.354,559.395 1073.977,559.395 C1109.599,559.395 1138.477,618.717 1138.477,691.895 C1138.477,765.072 1109.599,824.395 1073.977,824.395 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M356.5,824.395 C320.878,824.395 292,765.072 292,691.895 C292,618.717 320.878,559.395 356.5,559.395 C392.122,559.395 421,618.717 421,691.895 C421,765.072 392.122,824.395 356.5,824.395 z"
        fill="#FFDBAC"
        id="skin"
      />
    </g>
    <g id="features">
      <path d="M647.874,1039.086 L759.766,1039.086" fillOpacity="0" stroke="#000000" strokeWidth="10" id="mouth" opacity="0.2" />
      <path
        d="M709.586,926.5 C709.586,926.5 663.197,930.422 650.695,907.723 C634.127,877.639 658.195,830.223 690.948,665.15"
        fillOpacity="0"
        stroke="#000000"
        strokeWidth="10"
        id="nose"
        opacity="0.2"
      />
    </g>
    <g id="hair_layer">
      <path
        d="M927.802,389.771 C864.834,369.443 700.235,397.581 570.692,435.47 C488.922,459.387 455.908,518.792 411.889,597.396 C381.273,652.068 347.621,763.279 327.801,767.771 C307.981,772.264 268.928,703.564 253.107,655.564 C237.287,607.564 238.149,472.159 284.149,374.159 C330.149,276.159 358.709,227.02 518.792,146.205 C665.668,72.057 925.677,123.011 966.839,180.791 C1045.157,195.328 1114.166,274.96 1152.346,391.452 C1190.526,507.944 1183.459,594.783 1169.639,649.276 C1155.819,703.768 1129.846,775.279 1107.914,777.771 C1085.982,780.264 1035.621,657.279 1019.802,603.771 C1003.982,550.264 989.753,409.772 927.802,389.771 z"
        fill="#9D6214"
        id="hair"
      />
    </g>
  </svg>
);

export default React.memo(MAWomanFace);
