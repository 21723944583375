import React, { useState } from 'react';
import { Grid, Paper, Typography, Box, Collapse, Button } from '@material-ui/core';
import { KeyboardArrowDown as ChevDownIcon, KeyboardArrowUp as ChevUpIcon } from '@material-ui/icons';

import { Character, LabelTitles } from '../../data/types';
import { ageRangeDisplay } from '../../data/values';
import { arePropsDeepEqual } from '../../utils/reactUtils';
import { GenderIcon } from '../Icons/Gender';
import Face from '../Face/Face';

import useStyles from './styles';
import LabelBar from './LabelBar';

interface Props {
  character: Character;
  titles?: LabelTitles;
  leftButtons?: React.ReactNode;
  rightButtons?: React.ReactNode;
}

const HeadItem = ({ character, titles, leftButtons, rightButtons }: Props) => {
  const { fullName, desc, labels, gender, age, ethnicity, hairColour, notes } = character;
  const [expanded, setExpanded] = useState<boolean>();
  const canExpand: boolean = !!notes;
  const classes = useStyles({ canExpand });

  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <>
      <Grid item xs={6} sm={4} md={3} lg={2} className={classes.gridItem}>
        <Face age={age} gender={gender} ethnicity={ethnicity} hairColour={hairColour} />
        <Paper elevation={2} className={classes.paperPanel}>
          <Box display="flex" justifyContent="space-between" alignItems="start" className={classes.buttonPanel}>
            <Box>{leftButtons}</Box>
            <Box>{rightButtons}</Box>
          </Box>

          <Typography>{fullName || '?'}</Typography>
          <Typography variant="subtitle2">{desc}</Typography>
          <Box display="flex" justifyContent="space-evenly" alignItems="center" className={classes.infoPanel}>
            <GenderIcon gender={gender} className={classes.infoIcon} />
            <Typography variant="subtitle2">{ageRangeDisplay[age]}</Typography>
          </Box>
          {!!labels.length && titles && <LabelBar labels={labels} titles={titles} />}

          {canExpand && (
            <>
              <Collapse in={expanded}>
                <div className={classes.extraInfo}>
                  {notes.split('\n').map((i, key) => (
                    <Typography key={key} variant="body2">
                      {i}
                    </Typography>
                  ))}
                </div>
              </Collapse>
              <Button className={classes.expandBtn} onClick={toggleExpanded}>
                {expanded ? <ChevUpIcon fontSize="small" /> : <ChevDownIcon fontSize="small" />}
              </Button>
            </>
          )}
        </Paper>
      </Grid>
    </>
  );
};

export default React.memo(HeadItem, arePropsDeepEqual());
