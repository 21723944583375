import React, { useState, useContext, useEffect } from 'react';
import { v4 as uuid } from 'uuid';

import { AppData, Domain } from './types';
import { cloneSaveFormat, cloneDomains } from '../utils/clone';
import { getDataFromStorage, saveDataToStorage } from './localStorage';

interface AppContextOutput {
  appData: AppData;
  setAppData(appData: AppData);
  setDomain(domain: Domain);
  setDomains(domains: Domain[]);
}

const initialState: any = getDataFromStorage();
const initialOutput: AppContextOutput = {
  appData: initialState,
  setAppData: () => {},
  setDomain: () => {},
  setDomains: () => {},
};

const AppContext = React.createContext<AppContextOutput>(initialOutput);

const { Provider, Consumer: AppConsumer } = AppContext;

const AppProvider = ({ children }) => {
  const [appData, setAppData] = useState<AppData>(initialState);

  useEffect(() => {
    saveDataToStorage(appData);
  }, [appData]);

  const setDomains = (domains: Domain[]) => {
    const newAppData = cloneSaveFormat(appData);
    const newChars = cloneDomains(domains);
    newAppData.domains = newChars;

    setAppData(newAppData);
  };

  const setDomain = (domain: Domain) => {
    const newAppData = cloneSaveFormat(appData);
    const index = newAppData.domains.findIndex(({ id }) => id === domain.id);

    if (!domain.id) {
      domain.id = uuid();
    }

    if (index !== -1) {
      newAppData.domains.splice(index, 1, domain);
    } else {
      newAppData.domains.push(domain);
    }

    setAppData(newAppData);
  };

  const output: AppContextOutput = {
    appData,
    setAppData,
    setDomain,
    setDomains,
  };

  return <Provider value={output}>{children}</Provider>;
};

const useAppContext = (): AppContextOutput => {
  const context = useContext(AppContext);
  return context;
};

export { AppProvider, AppConsumer as SaveFormatConsumer, useAppContext };
