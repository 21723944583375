import makeThemedStyles from '../../components/Theme/makeThemedStyles';
import { grey } from '@material-ui/core/colors';

const useStyles = makeThemedStyles(theme => ({
  domainPage: {
    padding: '0.5em 1em',
    overflowX: 'hidden',
  },
  buttonBar: {
    margin: theme.spacing(0, -0.5),
    '& > *': {
      flex: 1,
      margin: theme.spacing(0, 0.5),
    },
  },
  btnEngaged: {
    '&, &:hover': {
      backgroundColor: grey[200],
    },
  },
  filterButtonEngaged: {
    '&, &:hover': {
      backgroundColor: grey[200],
    },
  },
  filterPanel: {
    backgroundColor: grey[200],
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  filterInputBox: {
    paddingRight: theme.spacing(1),
  },
  filterInput: {
    width: '100%',
  },
  labelFilterIcon: {
    fontSize: '0.75rem',
    margin: theme.spacing(0.25, 0),
  },
  groupBtns: {
    outline: '2px solid red',
  },
}));

export default useStyles;
