import React from 'react';

const UnknownFace = ({ className }) => (
  <svg version="1.1" x="0" y="0" width="1200" height="1200" viewBox="0, 0, 1200, 1200" className={className}>
    <path
      d="M615.74,901.649 L568.74,901.649 C535.64,901.649 508.74,928.549 508.74,961.649 L508.74,1026.549 C508.74,1059.649 535.64,1086.549 568.74,1086.549 L615.74,1086.549 C648.841,1086.549 675.74,1059.649 675.74,1026.549 L675.74,961.649 C675.74,928.45 648.841,901.649 615.74,901.649 z"
      fill="#000000"
      opacity="0.25"
    />
    <path
      d="M284.34,399.25 L371.04,410.05 C398.54,413.45 424.64,397.65 434.54,371.75 C447.04,339.05 464.44,313.25 486.74,294.45 C518.341,267.85 557.64,254.45 604.64,254.45 C653.34,254.45 692.14,267.25 720.94,292.75 C749.74,318.35 764.04,348.95 764.04,384.85 C764.04,410.65 755.94,434.25 739.74,455.65 C729.24,469.25 696.94,497.85 643.04,541.55 C589.04,585.25 553.141,624.649 535.141,659.649 C516.741,695.45 510.341,735.149 508.741,774.95 C507.342,809.05 534.541,837.45 568.741,837.45 L617.741,837.45 C648.941,837.45 674.741,813.55 677.541,782.55 C679.541,760.251 683.241,743.351 688.842,731.851 C698.241,712.15 722.541,686.15 761.541,653.751 C837.04,591.25 886.24,541.85 909.341,505.45 C932.341,469.15 943.941,430.65 943.941,389.95 C943.941,316.45 912.641,251.95 849.941,196.55 C787.341,141.15 702.941,113.45 596.941,113.45 C496.141,113.45 414.841,140.75 352.841,195.45 C300.041,242.05 267.941,297.25 256.641,360.95 C253.14,379.55 265.84,396.95 284.34,399.25 z"
      fill="#000000"
      opacity="0.25"
    />
  </svg>
);

export default React.memo(UnknownFace);
