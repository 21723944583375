export enum GENDER {
  MALE = 'male',
  FEMALE = 'female',
  UNKNOWN = 'unknown',
}

export enum AGE_RANGE {
  UNKNOWN = 'unknown',
  BABY = 'baby',
  CHILD = 'child',
  TEENAGER = 'teenager',
  TWENTIES = 'twenties',
  THIRTIES = 'thirties',
  MIDDLE_AGED = 'middle_aged',
  ELDERLY = 'elderly',
}

export enum CONNECTION_TYPE {
  MARRIED = 'married',
  DIVORCED = 'divorced',
  SIBLING = 'sibling',
  PARENT_OF = 'parent_of',
  CHILD_OF = 'child_of',
  IN_LOVE_WITH = 'in_love_with',
  FRIEND_OF = 'friend_of',
  BOSS_OF = 'boss_of',
  EMPLOYEE_OF = 'employee_of',
  COLLEAGUE_OF = 'colleague_of',
  ENEMY_OF = 'enemy_of',
}

export enum ETHNICITY {
  WHITE = 'white',
  MIXED_1 = 'mixed_1',
  MIXED_2 = 'mixed_2',
  MIXED_3 = 'mixed_3',
  BLACK = 'black',
}

export enum HAIR_COLOUR {
  BLONDE = 'blonde',
  BRUNETTE = 'brunette',
  AUBURN = 'auburn',
  BLACK = 'black',
  GRAY = 'gray',
}

export interface Connection {
  type: CONNECTION_TYPE;
  from: string;
  to: string;
}

export enum LabelKeys {
  GREEN = 'green',
  YELLOW = 'yellow',
  ORANGE = 'orange',
  RED = 'red',
  PURPLE = 'purple',
  BLUE = 'blue',
  PINK = 'pink',
  CYAN = 'cyan',
}

export interface Character {
  id: string;
  fullName: string;
  desc: string;
  labels: LabelKeys[];
  gender: GENDER;
  age: AGE_RANGE;
  ethnicity: ETHNICITY;
  hairColour: HAIR_COLOUR;
  connections: Connection[];
  notes?: string;
}

export interface LabelTitles {
  [LabelKeys.GREEN]: string;
  [LabelKeys.YELLOW]: string;
  [LabelKeys.ORANGE]: string;
  [LabelKeys.RED]: string;
  [LabelKeys.PURPLE]: string;
  [LabelKeys.BLUE]: string;
}

export interface Domain {
  id: string;
  title: string;
  subtitle?: string;
  labels: LabelTitles;
  characters: Character[];
}

export interface AppData {
  version: number;
  domains: Domain[];
}
