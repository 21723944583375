import { AppData, Domain, Character } from './types';
import { CURRENT_SAVE_FORMAT_VERSION } from './values';
import { getEmptyLabelTitles } from './empty';

export const updateVersion = data => {
  if (data.version !== CURRENT_SAVE_FORMAT_VERSION) {
    return updateVersion(appUpdaters[data.version](data.version)(data));
  }
  return data;
};

const appUpdaters = {
  1: version => (appData: AppData) => ({
    ...appData,
    version: 2,
    domains: appData.domains.map(domainUpdaters[version](version)),
  }),
};

const domainUpdaters = {
  1: version => (domain: Domain) => ({
    ...domain,
    labels: getEmptyLabelTitles(),
    characters: domain.characters.map(characterUpdaters[version](version)),
  }),
};

const characterUpdaters = {
  1: version => (char: Character) => ({
    ...char,
    labels: [],
  }),
};
