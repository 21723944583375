import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const ViewGroupIcon = props => (
  <SvgIcon viewBox="0, 0, 1417.323, 1417.323" {...props}>
    <path d="M375.23,248.714 L710.597,248.714 C735.403,248.714 755.512,268.823 755.512,293.629 L755.512,628.996 C755.512,653.802 735.403,673.911 710.597,673.911 L375.23,673.911 C350.424,673.911 330.315,653.802 330.315,628.996 L330.315,293.629 C330.315,268.823 350.424,248.714 375.23,248.714 z" />
    <path d="M989.432,248.714 L1324.799,248.714 C1349.605,248.714 1369.714,268.823 1369.714,293.629 L1369.714,628.996 C1369.714,653.802 1349.605,673.911 1324.799,673.911 L989.432,673.911 C964.626,673.911 944.517,653.802 944.517,628.996 L944.517,293.629 C944.517,268.823 964.626,248.714 989.432,248.714 z" />
    <g>
      <path d="M77.354,36.616 L1009.473,36.616 C1026.043,36.616 1039.476,50.022 1039.476,66.559 L1039.476,148.404 C1039.476,164.942 1026.043,178.348 1009.473,178.348 L77.354,178.348 C60.784,178.348 47.35,164.942 47.35,148.404 L47.35,66.559 C47.35,50.022 60.784,36.616 77.354,36.616 z" />
      <path d="M77.354,36.616 L1009.473,36.616 C1026.043,36.616 1039.476,50.022 1039.476,66.559 L1039.476,148.404 C1039.476,164.942 1026.043,178.348 1009.473,178.348 L77.354,178.348 C60.784,178.348 47.35,164.942 47.35,148.404 L47.35,66.559 C47.35,50.022 60.784,36.616 77.354,36.616 z" />
    </g>
    <g>
      <path d="M77.354,745.277 L1009.473,745.277 C1026.043,745.277 1039.476,758.683 1039.476,775.22 L1039.476,857.066 C1039.476,873.603 1026.043,887.009 1009.473,887.009 L77.354,887.009 C60.784,887.009 47.35,873.603 47.35,857.066 L47.35,775.22 C47.35,758.683 60.784,745.277 77.354,745.277 z" />
      <path d="M77.354,745.277 L1009.473,745.277 C1026.043,745.277 1039.476,758.683 1039.476,775.22 L1039.476,857.066 C1039.476,873.603 1026.043,887.009 1009.473,887.009 L77.354,887.009 C60.784,887.009 47.35,873.603 47.35,857.066 L47.35,775.22 C47.35,758.683 60.784,745.277 77.354,745.277 z" />
    </g>
    <path d="M375.23,959.126 L710.597,959.126 C735.403,959.126 755.512,979.235 755.512,1004.041 L755.512,1339.408 C755.512,1364.214 735.403,1384.323 710.597,1384.323 L375.23,1384.323 C350.424,1384.323 330.315,1364.214 330.315,1339.408 L330.315,1004.041 C330.315,979.235 350.424,959.126 375.23,959.126 z" />
    <path d="M989.432,959.126 L1324.799,959.126 C1349.605,959.126 1369.714,979.235 1369.714,1004.041 L1369.714,1339.408 C1369.714,1364.214 1349.605,1384.323 1324.799,1384.323 L989.432,1384.323 C964.626,1384.323 944.517,1364.214 944.517,1339.408 L944.517,1004.041 C944.517,979.235 964.626,959.126 989.432,959.126 z" />
  </SvgIcon>
);
