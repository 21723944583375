import { LabelKeys, GENDER, AGE_RANGE, ETHNICITY, HAIR_COLOUR } from './types';

export const CURRENT_SAVE_FORMAT_VERSION = 2;

export const labelKeys: LabelKeys[] = [LabelKeys.GREEN, LabelKeys.YELLOW, LabelKeys.ORANGE, LabelKeys.RED, LabelKeys.PURPLE, LabelKeys.BLUE, LabelKeys.PINK, LabelKeys.CYAN];

export const genderDisplay = {
  [GENDER.MALE]: 'Male',
  [GENDER.FEMALE]: 'Female',
  [GENDER.UNKNOWN]: 'Unknown',
};
export const ageRangeDisplay = {
  [AGE_RANGE.UNKNOWN]: 'Unknown',
  [AGE_RANGE.BABY]: 'Baby',
  [AGE_RANGE.CHILD]: 'Child',
  [AGE_RANGE.TEENAGER]: 'Teen',
  [AGE_RANGE.TWENTIES]: '20s',
  [AGE_RANGE.THIRTIES]: '30s',
  [AGE_RANGE.MIDDLE_AGED]: 'Middle Aged',
  [AGE_RANGE.ELDERLY]: 'Elderly',
};
export const ethnicityDisplay = {
  [ETHNICITY.WHITE]: 'Ethnicity 1',
  [ETHNICITY.MIXED_1]: 'Ethnicity 2',
  [ETHNICITY.MIXED_2]: 'Ethnicity 3',
  [ETHNICITY.MIXED_3]: 'Ethnicity 4',
  [ETHNICITY.BLACK]: 'Ethnicity 5',
};
export const hairColourDisplay = {
  [HAIR_COLOUR.BLONDE]: 'Blonde',
  [HAIR_COLOUR.BRUNETTE]: 'Brunette',
  [HAIR_COLOUR.AUBURN]: 'Auburn',
  [HAIR_COLOUR.BLACK]: 'Black',
  [HAIR_COLOUR.GRAY]: 'Gray',
};
