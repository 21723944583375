import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ReactGA from 'react-ga';

ReactGA.initialize('UA-24575380-12');

const usePageViews = () => {
  let location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);
};
const HistoryLogger = () => {
  usePageViews();
  return <></>;
};

export default HistoryLogger;
