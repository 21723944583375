import React from 'react';

const GirlFace = ({ className }) => (
  <svg version="1.1" x="0" y="0" width="1417.323" height="1417.323" viewBox="0, 0, 1417.323, 1417.323" className={className}>
    <g id="hair_behind">
      <path
        d="M1081.827,1125.094 C1055.929,1204.141 829.266,1259.094 759.266,1197.094 C689.266,1135.094 322.219,888.65 323.758,664.65 C325.297,440.65 559.309,388.918 712.748,388.918 C1076.344,476.794 1123.5,454.918 1126.562,670.918 C1129.625,886.918 1129.925,978.28 1081.827,1125.094 z"
        fill="#9D6214"
        id="hair"
      />
    </g>
    <g id="face">
      <path
        d="M703.811,216.595 C825.073,211.678 949.989,250.43 1007.811,344.595 C1061.539,432.094 1104.43,530.094 1099.811,652.595 C1094.678,788.734 1032,922.586 968,1038.586 C906.355,1150.317 849.225,1215.562 712,1216 C624.099,1216.28 529.077,1167.296 460,1060 C377.594,932 318.862,789.824 315.811,636.595 C312.893,490.094 367.277,396.098 445.539,318.094 C523.801,240.089 617.539,220.094 703.811,216.595 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M708.661,925.661 C785.644,926.269 845.66,986.857 846.631,1063.607 L846.631,1063.607 L846.631,1063.631 C846.631,1160.85 784.86,1239.661 708.661,1239.661 C632.463,1239.661 570.692,1160.85 570.692,1063.631 L570.692,1063.607 L570.692,1063.607 C571.306,986.614 631.915,926.633 708.661,925.661 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M1062.977,824.395 C1027.354,824.395 998.477,765.072 998.477,691.895 C998.477,618.717 1027.354,559.395 1062.977,559.395 C1098.599,559.395 1127.477,618.717 1127.477,691.895 C1127.477,765.072 1098.599,824.395 1062.977,824.395 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M356.5,824.395 C320.878,824.395 292,765.072 292,691.895 C292,618.717 320.878,559.395 356.5,559.395 C392.122,559.395 421,618.717 421,691.895 C421,765.072 392.122,824.395 356.5,824.395 z"
        fill="#FFDBAC"
        id="skin"
      />
    </g>
    <g id="features">
      <path d="M602,1030.21 C645.333,1053.544 784.667,1054.878 825.333,1030.21" fillOpacity="0" stroke="#000000" strokeWidth="10" id="mouth" opacity="0.2" />
      <path d="M709.586,926.5 C709.586,926.5 683.448,932.012 662,897.544 C648.234,875.422 653.552,877.967 680.734,758.117" fillOpacity="0" stroke="#000000" strokeWidth="10" id="nose" opacity="0.2" />
    </g>
    <g id="hair_layer">
      <path
        d="M697.004,163.28 C652.244,164.919 596.959,170.919 546.911,194.919 C496.863,218.919 454.911,240.253 414.911,281.586 C374.911,322.919 327.577,392.253 308.244,444.253 C288.911,496.253 282.732,565.636 284.93,603 C288.93,671 348.395,738.421 357.004,741 C365.613,743.579 384.077,716.494 393.676,642.18 C425.573,529.504 457.203,481.242 503.672,471.18 C550.141,461.117 677.232,477.855 695.676,478.18 C714.121,478.504 840.208,461.242 894.676,461.18 C949.145,461.117 1000.482,558.173 1017.676,616.18 C1034.871,674.186 1065.664,749.712 1080.911,744.253 C1096.158,738.793 1123.474,689.994 1126.481,608.253 C1129.751,519.375 1125.672,494.253 1103.578,440.919 C1070.679,361.506 1054.046,338.963 1009.004,291 C963.963,243.037 910.431,216.414 869.004,195 C827.577,173.586 741.765,161.64 697.004,163.28 z"
        fill="#9D6214"
        id="hair"
      />
      <path
        d="M706.987,258.28 C885.646,260.735 1029.055,391.362 1081.827,592.336 L1071.52,577.74 C1015.023,414.778 855.091,326.825 706.987,324.604 C557.137,325.686 402.596,412.734 344.52,575.371 L332.148,592.336 C382.203,393.197 531.958,260.903 706.987,258.28 L706.987,258.28 z"
        fill="#FFC0CB"
        id="hairband"
      />
    </g>
  </svg>
);

export default React.memo(GirlFace);
