import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { Character } from '../../data/types';

interface Props {
  character?: Character;
  confirm(character: Character);
  cancel();
}

const RemoveConfirm = ({ character, confirm, cancel }: Props) => {
  const char: any = character;

  const confirmHandler = () => confirm(character);

  return (
    <Dialog open={!!character}>
      <DialogTitle>Remove {char?.fullName || char?.desc}?</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to remove the character "{char?.fullName || char?.desc}"?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} color="secondary" variant="contained" children="Cancel" />
        <Button onClick={confirmHandler} color="primary" variant="contained" children="Remove" />
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(RemoveConfirm);
