import React, { useState } from 'react';
import { Box, Typography, Button, Dialog, Paper, Collapse, Input, InputAdornment, IconButton } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { Edit as EditIcon, FilterList as FilterIcon, Cancel as CancelIcon } from '@material-ui/icons';

import { Character, Domain, LabelKeys } from '../../data/types';
import { withDomainProvider, useDomainContext } from '../../data/DomainContext';
import NewCharacterBtn from '../CharacterEdit/NewCharacterBtn';
import CharacterEdit from '../CharacterEdit/CharacterEdit';
import ResponsiveDialog from '../ResponsiveDialog/ResponsiveDialog';
import DomainEdit from './DomainEdit';
import useStyles from './styles';
import { labelKeys } from '../../data/values';
import { useToggleState } from '../../utils/reactUtils';
import { useFormItemState } from '../Form/useFormState';
import { CircleIcon } from '../Icons/Circle';
import { getLabelTextStyle } from '../../utils/colours';
import CharacterList from './CharacterList';
import { ViewGroupIcon } from '../Icons/ViewGroup';
import { cloneCharacters } from '../../utils/clone';
import RemoveConfirm from './RemoveConfirm';

interface Props {
  domain: Domain;
}

const DomainComponent = () => {
  const classes = useStyles();
  const { domain, setCharacters } = useDomainContext();
  const [editingDom, setEditingDom] = useState<boolean>(false);
  const [editingChar, setEditingChar] = useState<Character | null>(null);
  const [removeChar, setRemoveChar] = useState<Character | null>(null);
  const [showFilter, toggleShowFilter] = useToggleState(false);
  const filterText = useFormItemState('');
  const [filterLabels, setFilterLabels] = useState<LabelKeys[]>([]);
  const [bySwimlane, toggleBySwimlane] = useToggleState(true);

  const { characters, labels } = domain;
  const filteredChars = (() => {
    let filt = characters;
    if (filterText.value) {
      filt = filt.filter(({ fullName }) => fullName.indexOf(filterText.value) !== -1);
    }
    if (filterLabels.length) {
      filt = filt.filter(char => !filterLabels.find(lbl => !char.labels.includes(lbl)));
    }
    return filt;
  })();

  const openDomEdit = () => setEditingDom(true);
  const onEditDomClose = () => setEditingDom(false);
  const onEditCharClose = () => setEditingChar(null);

  const handleLabelChange = (e, newValue) => setFilterLabels(newValue);

  const remove = character => setRemoveChar(character);
  const removeConfirm = (character: Character) => {
    const index = characters.indexOf(character);
    const newOrder = cloneCharacters(characters);
    newOrder.splice(index, 1);
    setCharacters(newOrder);
    setRemoveChar(null);
  };
  const removeCancel = () => setRemoveChar(null);

  return (
    <div className={classes.domainPage}>
      <Typography variant="h5" align="center" children={domain.title} />
      <Typography variant="h6" align="center" children={domain.subtitle} gutterBottom />

      <Box display="flex" justifyContent="space-evenly" alignItems="flex-start" className={classes.buttonBar}>
        <Button startIcon={<EditIcon />} onClick={openDomEdit} children="Edit" />
        <NewCharacterBtn btnText="Add" />
        {!!characters.length && (
          <>
            <Button startIcon={<ViewGroupIcon />} onClick={toggleBySwimlane} children="Group" className={bySwimlane ? classes.btnEngaged : ''} />
            <Button startIcon={<FilterIcon />} onClick={toggleShowFilter} children="Filter" className={showFilter ? classes.filterButtonEngaged : ''} />
          </>
        )}
      </Box>

      <Collapse in={showFilter}>
        <Paper className={classes.filterPanel}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} className={classes.filterInputBox}>
              <Input
                value={filterText.value}
                onChange={filterText.onChange}
                className={classes.filterInput}
                endAdornment={
                  !!filterText.value && (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={filterText.setTo('')}>
                        <CancelIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </Box>
            <Box>
              <ToggleButtonGroup value={filterLabels} onChange={handleLabelChange} size="small">
                {labelKeys.map(key => (
                  <ToggleButton key={key} value={key} aria-label={key}>
                    <CircleIcon size="small" fontSize="small" className={classes.labelFilterIcon} style={getLabelTextStyle(key)} />
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>
          </Box>
        </Paper>
      </Collapse>

      <CharacterList characters={filteredChars} labelTitles={labels} edit={setEditingChar} bySwimlane={bySwimlane && !filterText.value} remove={remove} />

      <br />
      <Box display="flex" justifyContent="space-evenly">
        <NewCharacterBtn btnType="fab" btnText="Add Character" variant="contained" color="primary" />
      </Box>

      <Dialog open={editingDom} onClose={onEditDomClose} maxWidth="md" className={classes.dialog}>
        <DomainEdit domain={domain} close={onEditDomClose} />
      </Dialog>
      <ResponsiveDialog open={!!editingChar} onClose={onEditCharClose} maxWidth="md" className={classes.dialog}>
        {editingChar && <CharacterEdit character={editingChar} close={onEditCharClose} />}
      </ResponsiveDialog>
      <RemoveConfirm character={removeChar} confirm={removeConfirm} cancel={removeCancel} />
    </div>
  );
};

export default withDomainProvider(DomainComponent);
