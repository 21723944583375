import React from 'react';
import { Box, Button } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { useAppContext } from '../../data/AppContext';
import useStyles from './styles';

interface Props {}

const SaveLoad = () => {
  const classes = useStyles();
  const { appData, setAppData } = useAppContext();

  const download = () => {
    const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(appData));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', `badwithnames-save-${new Date().toISOString().substr(0, 10)}.json`);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  return (
    <Box display="flex" justifyContent="space-evenly" alignItems="center" className={classes.saveLoad}>
      <Button variant="contained" color="primary" startIcon={<GetApp />} onClick={download}>
        Download Data
      </Button>
    </Box>
  );
};

export default React.memo(SaveLoad);
