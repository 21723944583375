import React from 'react';

const WomanFace = ({ className }) => (
  <svg version="1.1" x="0" y="0" width="1417.323" height="1417.323" viewBox="0, 0, 1417.323, 1417.323" className={className}>
    <g id="hair_behind">
      <path
        d="M1051.539,1122.094 C989.539,1170.094 497.539,1154.094 395.539,1130.094 C293.539,1106.094 290.461,952.094 292,728.094 C293.539,504.094 571.223,442.094 724.662,442.094 C1088.258,529.97 1135.414,508.094 1138.476,724.094 C1141.539,940.094 1113.539,1074.094 1051.539,1122.094 z"
        fill="#9D6214"
        id="hair"
      />
    </g>
    <g id="face">
      <path
        d="M703.811,216.595 C825.073,211.678 949.989,250.43 1007.811,344.595 C1061.539,432.094 1104.43,530.094 1099.811,652.595 C1094.678,788.734 1061.539,884.094 997.539,1000.094 C935.894,1111.825 845.886,1219.656 708.661,1220.094 C620.761,1220.374 502.616,1137.39 433.539,1030.094 C351.133,902.094 318.862,789.824 315.811,636.595 C312.893,490.094 367.277,396.098 445.539,318.094 C523.801,240.089 617.539,220.094 703.811,216.595 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M708.661,925.661 C785.644,926.269 845.66,986.857 846.631,1063.607 L846.631,1063.607 L846.631,1063.631 C846.631,1160.85 784.86,1239.661 708.661,1239.661 C632.463,1239.661 570.692,1160.85 570.692,1063.631 L570.692,1063.607 L570.692,1063.607 C571.306,986.614 631.915,926.633 708.661,925.661 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M1073.977,824.395 C1038.354,824.395 1009.477,765.072 1009.477,691.895 C1009.477,618.717 1038.354,559.395 1073.977,559.395 C1109.599,559.395 1138.477,618.717 1138.477,691.895 C1138.477,765.072 1109.599,824.395 1073.977,824.395 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M356.5,824.395 C320.878,824.395 292,765.072 292,691.895 C292,618.717 320.878,559.395 356.5,559.395 C392.122,559.395 421,618.717 421,691.895 C421,765.072 392.122,824.395 356.5,824.395 z"
        fill="#FFDBAC"
        id="skin"
      />
    </g>
    <g id="features">
      <path d="M647.874,1039.086 L759.766,1039.086" fillOpacity="0" stroke="#000000" strokeWidth="10" id="mouth" opacity="0.2" />
      <path d="M709.586,926.5 C709.586,926.5 678.938,934.333 655.766,901 C633.099,857 663.766,785 690.948,665.15" fillOpacity="0" stroke="#000000" strokeWidth="10" id="nose" opacity="0.2" />
    </g>
    <g id="hair_layer">
      <path
        d="M927.802,389.771 C736.336,441.226 726.141,425.279 642.061,473.771 C557.982,522.264 487.622,585.279 439.802,625.771 C391.981,666.264 347.621,763.279 327.801,767.771 C307.981,772.264 261.622,712.263 245.802,664.263 C229.981,616.264 232,474 278,376 C324,278 365.636,209.401 525.719,128.586 C672.595,54.438 917.982,92.264 963.982,188.264 C1029.982,190.264 1121.622,273.279 1159.802,389.771 C1197.982,506.264 1197.622,593.279 1183.802,647.771 C1169.982,702.264 1129.846,775.279 1107.914,777.771 C1085.982,780.264 1035.621,657.279 1019.802,603.771 C1003.982,550.264 943.802,428.771 927.802,389.771 z"
        fill="#9D6214"
        id="hair"
      />
    </g>
  </svg>
);

export default React.memo(WomanFace);
