import { HAIR_COLOUR, ETHNICITY, LabelKeys, GENDER } from '../data/types';
import { green, yellow, orange, red, purple, blue, cyan, pink } from '@material-ui/core/colors';

export const genderColours = {
  [GENDER.MALE]: { colour: '#0074D9', bgColour: '#0074D911' },
  [GENDER.FEMALE]: { colour: '#FF69B4', bgColour: '#FFC0CB22' },
  [GENDER.UNKNOWN]: { colour: '#2ECC40', bgColour: '#2ECC4022' },
};

export const hairColours = {
  [HAIR_COLOUR.BLONDE]: '#d0b393',
  [HAIR_COLOUR.BRUNETTE]: '#493424',
  [HAIR_COLOUR.AUBURN]: '#5e0808',
  [HAIR_COLOUR.BLACK]: '#212121',
  [HAIR_COLOUR.GRAY]: '#cccccc',
};

export const skinColours = {
  [ETHNICITY.WHITE]: '#ffdbac',
  [ETHNICITY.MIXED_1]: '#f1c27d',
  [ETHNICITY.MIXED_2]: '#e0ac69',
  [ETHNICITY.MIXED_3]: '#c68642',
  [ETHNICITY.BLACK]: '#8d5524',
};

export const labelColours = {
  [LabelKeys.GREEN]: green[500],
  [LabelKeys.YELLOW]: yellow[500],
  [LabelKeys.ORANGE]: orange[500],
  [LabelKeys.RED]: red[500],
  [LabelKeys.PURPLE]: purple[300],
  [LabelKeys.BLUE]: blue[300],
  [LabelKeys.PINK]: pink[400],
  [LabelKeys.CYAN]: cyan[300],
};
export const labelTextColours = {
  [LabelKeys.GREEN]: 'black',
  [LabelKeys.YELLOW]: 'black',
  [LabelKeys.ORANGE]: 'black',
  [LabelKeys.RED]: 'black',
  [LabelKeys.PURPLE]: 'black',
  [LabelKeys.BLUE]: 'black',
  [LabelKeys.PINK]: 'black',
  [LabelKeys.CYAN]: 'black',
};

export const getLabelStyle = (bg: boolean = true, text: boolean = true) => key => ({
  backgroundColor: bg ? labelColours[key] : undefined,
  color: text ? labelTextColours[key] : undefined,
});

export const getLabelTextStyle = key => ({
  color: labelColours[key],
});
