import React from 'react';
import { Button, Box, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { labelKeys } from '../../data/values';
import { Domain } from '../../data/types';

import { useFormState, convertFormStateToObject } from '../Form/useFormState';
import Input from '../Form/Input';
import { useDomainContext } from '../../data/DomainContext';
import { CircleIcon } from '../Icons/Circle';
import { labelColours } from '../../utils/colours';

import useStyles from './editStyles';

const useDomainFormStates = useFormState(['title', 'subtitle']);
const useLabelFormStates = useFormState(labelKeys as string[]);

interface Props {
  domain: Domain;
  close();
}
const DomainEdit = ({ domain, close }: Props) => {
  const { updateDomain } = useDomainContext();
  const classes = useStyles();
  const form = useDomainFormStates(domain);
  const labels = useLabelFormStates(domain.labels);

  const onSave = () => {
    const newDomain = {
      ...domain,
      ...convertFormStateToObject(form),
      labels: {
        ...domain.labels,
        ...convertFormStateToObject(labels),
      },
    };
    updateDomain(newDomain);
    close();
  };

  return (
    <div className={classes.domainEdit}>
      <Input label="Title" formItem={form.title} />
      <Input label="Subtitle" formItem={form.subtitle} />

      <List>
        {labelKeys.map(key => (
          <ListItem key={key} className={classes.labelItem}>
            <ListItemIcon>
              <CircleIcon style={{ color: labelColours[key] }} />
            </ListItemIcon>
            <ListItemText>
              <Input formItem={labels[key]} childProps={{ size: 'small' }} />
            </ListItemText>
          </ListItem>
        ))}
      </List>

      <Box display="flex" justifyContent="space-evenly">
        <Button variant="contained" color="primary" onClick={onSave} children="Save" />
        <Button variant="contained" color="secondary" onClick={close} children="Cancel" />
      </Box>
    </div>
  );
};

export default DomainEdit;
