import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { GENDER } from '../../data/types';

export const MaleIcon = props => (
  <SvgIcon viewBox="0 0 512 512" {...props}>
    <path d="m276.956 0v57.674h136.589l-101.389 101.389c-32.544-24.144-72.837-38.431-116.471-38.431-108.074 0-195.685 87.61-195.685 195.684 0 108.073 87.611 195.684 195.684 195.684s195.684-87.611 195.684-195.684c0-43.634-14.287-83.928-38.431-116.472l101.389-101.388v136.589h57.674v-235.045zm-81.272 447.552c-72.48 0-131.237-58.757-131.237-131.237s58.757-131.237 131.237-131.237 131.237 58.757 131.237 131.237c0 72.481-58.757 131.237-131.237 131.237z" />
  </SvgIcon>
);
export const FemaleIcon = props => (
  <SvgIcon viewBox="0 0 512 512" {...props}>
    <path d="m401.9 145.9c0-80.578-65.321-145.9-145.9-145.9s-145.9 65.322-145.9 145.9c0 70.706 50.298 129.659 117.063 143.045v103.209h-61.891v57.674h61.891v62.172h57.674v-62.172h61.891v-57.674h-61.891v-103.209c66.766-13.386 117.063-72.339 117.063-143.045zm-236.628 0c0-50.108 40.62-90.728 90.728-90.728s90.728 40.62 90.728 90.728-40.62 90.728-90.728 90.728-90.728-40.62-90.728-90.728z" />
  </SvgIcon>
);
export const UnknownIcon = props => (
  <SvgIcon viewBox="0 0 973.1 973.1" {...props}>
    <path
      d="M502.29,788.199h-47c-33.1,0-60,26.9-60,60v64.9c0,33.1,26.9,60,60,60h47c33.101,0,60-26.9,60-60v-64.9
C562.29,815,535.391,788.199,502.29,788.199z"
    />
    <path
      d="M170.89,285.8l86.7,10.8c27.5,3.4,53.6-12.4,63.5-38.3c12.5-32.7,29.9-58.5,52.2-77.3c31.601-26.6,70.9-40,117.9-40
c48.7,0,87.5,12.8,116.3,38.3c28.8,25.6,43.1,56.2,43.1,92.1c0,25.8-8.1,49.4-24.3,70.8c-10.5,13.6-42.8,42.2-96.7,85.9
c-54,43.7-89.899,83.099-107.899,118.099c-18.4,35.801-24.8,75.5-26.4,115.301c-1.399,34.1,25.8,62.5,60,62.5h49
c31.2,0,57-23.9,59.8-54.9c2-22.299,5.7-39.199,11.301-50.699c9.399-19.701,33.699-45.701,72.699-78.1
C723.59,477.8,772.79,428.4,795.891,392c23-36.3,34.6-74.8,34.6-115.5c0-73.5-31.3-138-94-193.4c-62.6-55.4-147-83.1-253-83.1
c-100.8,0-182.1,27.3-244.1,82c-52.8,46.6-84.9,101.8-96.2,165.5C139.69,266.1,152.39,283.5,170.89,285.8z"
    />
  </SvgIcon>
);

interface Props extends SvgIconProps {
  gender: GENDER;
}

export const GenderIcon = ({ gender, ...props }: Props) => {
  switch (gender) {
    case GENDER.MALE:
      return <MaleIcon {...props} />;
    case GENDER.FEMALE:
      return <FemaleIcon {...props} />;
    case GENDER.UNKNOWN:
      return <UnknownIcon {...props} />;
  }
};
