import React from 'react';

const MAManFace = ({ className }) => (
  <svg version="1.1" x="0" y="0" width="1417.323" height="1417.323" viewBox="0, 0, 1417.323, 1417.323" className={className}>
    <g id="face">
      <path
        d="M703.811,216.595 C825.169,217.554 949.246,250.891 1007.811,344.595 C1087.811,472.595 1108.045,512.609 1099.811,652.595 C1091.811,788.595 1044.998,920.911 984.132,1038.586 C924.132,1154.586 941.696,1236.78 709.406,1238.531 C463.768,1240.383 490.077,1138.591 421,1031.295 C338.594,903.295 315.811,789.854 315.811,636.595 C324.778,435.221 373.549,402.6 451.811,324.595 C530.072,246.591 582.826,218.126 703.811,216.595 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M708.661,947.661 C785.644,948.269 845.66,1008.857 846.631,1085.607 L846.631,1085.607 L846.631,1085.631 C846.631,1182.85 784.86,1261.661 708.661,1261.661 C632.463,1261.661 570.692,1182.85 570.692,1085.631 L570.692,1085.607 L570.692,1085.607 C571.306,1008.614 631.915,948.633 708.661,947.661 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M1073.977,824.395 C1038.354,824.395 1009.477,765.072 1009.477,691.895 C1009.477,618.717 1038.354,559.395 1073.977,559.395 C1109.599,559.395 1138.477,618.717 1138.477,691.895 C1138.477,765.072 1109.599,824.395 1073.977,824.395 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M356.5,824.395 C320.878,824.395 292,765.072 292,691.895 C292,618.717 320.878,559.395 356.5,559.395 C392.122,559.395 421,618.717 421,691.895 C421,765.072 392.122,824.395 356.5,824.395 z"
        fill="#FFDBAC"
        id="skin"
      />
    </g>
    <g id="features">
      <path d="M608.161,1039.086 L810.161,1039.086" fillOpacity="0" stroke="#000000" strokeWidth="10" id="mouth" opacity="0.2" />
      <path d="M709.161,948.161 C586.892,950.046 618.334,901.311 690.937,665.086" fillOpacity="0" stroke="#000000" strokeWidth="10" id="nose" opacity="0.2" />
      <path d="M678.938,1156.086 C692.959,1143.659 713.159,1143.859 725.161,1156.086" fillOpacity="0" stroke="#000000" strokeWidth="10" id="dimple" opacity="0.2" />
    </g>
    <g id="hair_layer">
      <path
        d="M721.909,117.495 C772.102,116.161 824.198,128.162 874.246,152.162 C924.294,176.162 972.294,212.162 1014.294,258.828 C1067.387,317.821 1089.603,402.065 1097.995,480.901 C1106.388,559.738 1101.804,571.037 1099.606,608.401 C1095.606,676.401 1101.277,752.637 1080.03,756.178 C1061.165,759.322 1054.708,679.571 1045.109,605.257 C1037.673,547.688 1045.302,535.801 1015.239,481.061 C985.177,426.321 974.684,391.294 941.351,355.294 C926.684,375.294 868.185,432.495 780.997,430.754 C693.809,429.014 502.438,346.658 474.438,315.991 C454.438,350.658 416.571,426.359 389.545,479.489 C377.303,503.071 364.726,583.247 364.392,603.685 C373.595,703.685 362.346,763.209 347.099,757.75 C331.851,752.291 323.38,661.844 320.373,580.103 C317.103,491.226 309.341,484.27 319.617,414.411 C329.893,344.553 359.676,268.828 406.838,237.386 C430.42,180.791 506.936,149.289 562.569,133.969 C618.202,118.648 671.716,118.828 721.909,117.495 z"
        fill="#9D6214"
        id="hair"
      />
    </g>
  </svg>
);

export default React.memo(MAManFace);
