import makeThemedStyles from '../../components/Theme/makeThemedStyles';

const drawerWidth = 260;

const useStyles = makeThemedStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: 5000,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,

    '& > .MuiPaper-root': {
      maxWidth: drawerWidth,

      '& .MuiListItemText-root > .MuiTypography-root': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },

      '& .MuiListItemIcon-root': {
        minWidth: '2.5rem',
      },
      '& .MuiListItemText-multiline': {
        marginTop: 0,
        marginBottom: 0,
      },
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  nested: {
    '& .MuiListItem-root': {
      paddingLeft: theme.spacing(4),
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0, 0),
  },
}));

export default useStyles;
