import React from 'react';
import { Slider } from '@material-ui/core';

import { FormStateItem } from '../Form/useFormState';
import { AGE_RANGE } from '../../data/types';
import { ageRangeDisplay } from '../../data/values';
import makeThemedStyles from '../Theme/makeThemedStyles';

const useStyles = makeThemedStyles(theme => ({
  container: {
    padding: theme.spacing(0, 1),
    marginBottom: theme.spacing(1),
  },
}));

interface Props {
  formItem: FormStateItem;
}

const ages = [AGE_RANGE.UNKNOWN, AGE_RANGE.BABY, AGE_RANGE.CHILD, AGE_RANGE.TEENAGER, AGE_RANGE.TWENTIES, AGE_RANGE.THIRTIES, AGE_RANGE.MIDDLE_AGED, AGE_RANGE.ELDERLY];
const percents = [0, 6, 17, 28, 39, 50, 70, 90];
// const percents = [0, 12, 23, 34, 45, 56, 73, 93];

const marks = ages.map((age, index) => ({
  // value: (100 / (ages.length + 1)) * (index + 1),
  value: percents[index],
  age,
  label: age === AGE_RANGE.UNKNOWN ? '' : ageRangeDisplay[age],
}));

const AgeSlider = ({ formItem }) => {
  const classes = useStyles();
  const { value, set } = formItem;
  const currentMark = marks.find(mark => mark.age === value);

  const onChange = (e, newValue) => {
    const newMark = marks.find(mark => mark.value === newValue);
    if (newMark !== currentMark) {
      set(newMark.age);
    }
  };

  return (
    <div className={classes.container}>
      <Slider value={currentMark.value} onChange={onChange} step={null} valueLabelDisplay="off" marks={marks} />
    </div>
  );
};

export default React.memo(AgeSlider);
