import React, { useState, useContext } from 'react';
import { v4 as uuid } from 'uuid';

import { Domain, Character } from './types';
import { useAppContext } from './AppContext';
import { cloneDomain, cloneCharacters } from '../utils/clone';
import { getEmptyDomain } from './empty';

interface DomainContextOutput {
  domain: Domain;
  updateDomain(domain: Domain);
  setCharacter(character: Character);
  setCharacters(characters: Character[]);
}

const initialState: any = getEmptyDomain();
const initialOutput: DomainContextOutput = {
  domain: initialState,
  updateDomain: () => {},
  setCharacter: () => {},
  setCharacters: () => {},
};

const DomainContext = React.createContext<DomainContextOutput>(initialOutput);

const { Provider, Consumer: DomainConsumer } = DomainContext;

const DomainProvider = ({ children, initialData }) => {
  const { setDomain: setDomainInApp } = useAppContext();
  const [domain, setDomain] = useState<Domain>(initialData);

  const setCharacters = (characters: Character[]) => {
    const newDomain = cloneDomain(domain);
    const newChars = cloneCharacters(characters);
    newDomain.characters = newChars;

    setDomainInApp(newDomain);
    setDomain(newDomain);
  };

  const setCharacter = (character: Character) => {
    const newDomain = cloneDomain(domain);
    const index = newDomain.characters.findIndex(({ id }) => id === character.id);

    if (!character.id) {
      character.id = uuid();
    }

    if (index !== -1) {
      newDomain.characters.splice(index, 1, character);
    } else {
      newDomain.characters.push(character);
    }

    setDomainInApp(newDomain);
    setDomain(newDomain);
  };

  const updateDomain = (domain: Domain) => {
    const newDomain = cloneDomain(domain);
    setDomainInApp(newDomain);
    setDomain(newDomain);
  };

  const output: DomainContextOutput = {
    domain,
    updateDomain,
    setCharacter,
    setCharacters,
  };

  return <Provider value={output}>{children}</Provider>;
};

const useDomainContext = (): DomainContextOutput => {
  const context = useContext(DomainContext);
  return context;
};

interface WithDomainProviderProps {
  children?: any;
  domain?: Domain;
  [x: string]: any;
}

const withDomainProvider = Component => {
  return ({ children, ...props }: WithDomainProviderProps) => {
    const { domain } = props;
    return (
      <DomainProvider initialData={domain}>
        <Component {...props}>{children}</Component>
      </DomainProvider>
    );
  };
};

export { DomainProvider, DomainConsumer, useDomainContext, withDomainProvider };
