import React from 'react';

import { GENDER } from '../../data/types';
import { genderDisplay } from '../../data/values';

import { FormStateItem } from '../Form/useFormState';
import RadioBtnGroup, { RadioBtnOption } from '../Form/RadioBtn';
import { MaleIcon, FemaleIcon, UnknownIcon } from '../Icons/Gender';

interface Props {
  formItem: FormStateItem;
}

const options: RadioBtnOption[] = [
  {
    value: GENDER.MALE,
    label: genderDisplay[GENDER.MALE],
    icon: <MaleIcon />,
    colour: '#0074D9',
    bgColour: '#0074D911',
  },
  {
    value: GENDER.FEMALE,
    label: genderDisplay[GENDER.FEMALE],
    icon: <FemaleIcon />,
    colour: '#FF69B4',
    bgColour: '#FFC0CB22',
  },
  {
    value: GENDER.UNKNOWN,
    label: genderDisplay[GENDER.UNKNOWN],
    icon: <UnknownIcon />,
    colour: '#2ECC40',
    bgColour: '#2ECC4022',
  },
];

const GenderRadio = ({ formItem }) => {
  return <RadioBtnGroup formItem={formItem} options={options} />;
};

export default React.memo(GenderRadio);
