import React from 'react';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import makeThemedStyles from '../Theme/makeThemedStyles';
import { clj } from '../../utils/reactUtils';

const useStyles = makeThemedStyles(theme => ({
  expIcon: {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short,
    }),
  },
  expIconOpen: {
    transform: 'rotate(-180deg)',
  },
  expIconClosed: {
    transform: 'rotate(0)',
  },
}));

interface Props {
  open: boolean;
  iconProps?: any;
}

const ExpandIcon = ({ open, iconProps }: Props) => {
  const classes = useStyles();

  return <ExpandMoreIcon className={clj(classes.expIcon, open ? classes.expIconOpen : classes.expIconClosed)} {...iconProps} />;
};

export default React.memo(ExpandIcon);
