import { AppData } from './types';
import { updateVersion } from './update';
import { getEmptySaveFormat } from './empty';

const SAVE_KEY = 'badwithnames_savedata';

export const getDataFromStorage = (): AppData => {
  let data;

  try {
    const raw = localStorage.getItem(SAVE_KEY);
    data = JSON.parse(raw);
    data = updateVersion(data);
  } catch (e) {}

  return data || getEmptySaveFormat();
};

export const saveDataToStorage = (data: AppData) => {
  const json = JSON.stringify(data);
  localStorage.setItem(SAVE_KEY, json);
};
