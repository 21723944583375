import React from 'react';
import { BabyFace, BoyFace, GirlFace, ManFace, WomanFace, MAManFace, MAWomanFace, OldManFace, OldWomanFace, UnknownFace, MaleSymbolFace, FemaleSymbolFace } from './svg/index';
import useStyles from './styles';
import { AGE_RANGE, ETHNICITY, HAIR_COLOUR, GENDER } from '../../data/types';
import { Paper } from '@material-ui/core';
import { clj } from '../../utils/reactUtils';

interface Props {
  age: AGE_RANGE;
  gender: GENDER;
  ethnicity: ETHNICITY;
  hairColour: HAIR_COLOUR;
  className?: string;
}

const FaceSVG = React.memo(({ age, gender, ethnicity, hairColour }: Props) => {
  const classes = useStyles({ gender, ethnicity, hairColour });

  if (gender === GENDER.MALE) {
    switch (age) {
      case AGE_RANGE.UNKNOWN:
        return <MaleSymbolFace className={clj(classes.faceSVG, classes.genderSVG)} />;
      case AGE_RANGE.BABY:
        return <BabyFace className={classes.faceSVG} />;
      case AGE_RANGE.CHILD:
      case AGE_RANGE.TEENAGER:
        return <BoyFace className={classes.faceSVG} />;
      case AGE_RANGE.TWENTIES:
      case AGE_RANGE.THIRTIES:
        return <ManFace className={classes.faceSVG} />;
      case AGE_RANGE.MIDDLE_AGED:
        return <MAManFace className={classes.faceSVG} />;
      case AGE_RANGE.ELDERLY:
        return <OldManFace className={classes.faceSVG} />;
    }
  } else if (gender === GENDER.FEMALE) {
    switch (age) {
      case AGE_RANGE.UNKNOWN:
        return <FemaleSymbolFace className={clj(classes.faceSVG, classes.genderSVG)} />;
      case AGE_RANGE.BABY:
        return <BabyFace className={classes.faceSVG} />;
      case AGE_RANGE.CHILD:
      case AGE_RANGE.TEENAGER:
        return <GirlFace className={classes.faceSVG} />;
      case AGE_RANGE.TWENTIES:
      case AGE_RANGE.THIRTIES:
        return <WomanFace className={classes.faceSVG} />;
      case AGE_RANGE.MIDDLE_AGED:
        return <MAWomanFace className={classes.faceSVG} />;
      case AGE_RANGE.ELDERLY:
        return <OldWomanFace className={classes.faceSVG} />;
    }
  } else {
    return <UnknownFace className={classes.faceSVG} />;
  }
});

const Face = (props: Props) => {
  const classes = useStyles();

  const className = `${classes.paper} ${props.className}`;

  return (
    <Paper className={className}>
      <FaceSVG {...props} />
    </Paper>
  );
};

export default React.memo(Face);
