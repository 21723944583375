import React from 'react';
import { Character, GENDER, AGE_RANGE } from '../../data/types';
import { useFormState, convertFormStateToObject } from '../Form/useFormState';
import useStyles from './styles';
import Input from '../Form/Input';
import GenderRadio from './GenderRadio';
import EthnicityRadio from './EthnicityRadio';
import Face from '../Face/Face';
import { Button, Box, Collapse } from '@material-ui/core';
import HairColourRadio from './HairColourRadio';
import AgeSlider from './AgeSlider';
import { useDomainContext } from '../../data/DomainContext';
import LabelEditor from './LabelEditor';

const useCharacterFormStates = useFormState(['fullName', 'desc', 'labels', 'gender', 'age', 'ethnicity', 'hairColour', 'notes']);

interface Props {
  character: Character;
  close();
}
const CharacterEdit = ({ character, close }: Props) => {
  const { domain, setCharacter } = useDomainContext();
  const classes = useStyles();
  const form = useCharacterFormStates(character);

  const showCharacteristics = form.gender.value !== GENDER.UNKNOWN;
  const showAppearance = form.age.value !== AGE_RANGE.UNKNOWN;

  const onSave = () => {
    const newChar = {
      ...character,
      ...convertFormStateToObject(form),
    };
    setCharacter(newChar);
    close();
  };

  return (
    <div className={classes.characterEdit}>
      <div className={classes.face}>
        <Face age={form.age.value} gender={form.gender.value} ethnicity={form.ethnicity.value} hairColour={form.hairColour.value} />
      </div>

      <LabelEditor formItem={form.labels} titles={domain.labels} />

      <Input label="Full Name" formItem={form.fullName} />
      <Input label="Short Description" formItem={form.desc} />
      <GenderRadio formItem={form.gender} />

      <Collapse in={showCharacteristics}>
        <AgeSlider formItem={form.age} />
        <Collapse in={showAppearance}>
          <EthnicityRadio formItem={form.ethnicity} />
          <HairColourRadio formItem={form.hairColour} />
        </Collapse>
      </Collapse>

      <Input label="Notes" formItem={form.notes} childProps={{ multiline: true, rows: 4, variant: 'outlined' }} />

      <Box display="flex" justifyContent="space-evenly">
        <Button variant="contained" color="primary" onClick={onSave} children="Save" />
        <Button variant="contained" color="secondary" onClick={close} children="Cancel" />
      </Box>
    </div>
  );
};

export default CharacterEdit;
