import { useState } from 'react';

const isEqual = require('react-fast-compare');

export const clj = (...args) => args.join(' '); // className join

export const arePropsDeepEqual = (include?: string[], exclude?: string[]) => (prev, next) => {
  if (!include && !exclude) {
    return isEqual(prev, next);
  }

  if (!include) {
    include = Object.keys(next);
  }
  if (!exclude) {
    exclude = [];
  }

  const keys = include.filter(key => !exclude.includes(key));

  for (let key of keys) {
    const check = isEqual(prev[key], next[key]);
    if (!check) {
      return false;
    }
  }
  return true;
};

export const useToggleState = (initial?: any) => {
  const [value, set] = useState(initial);
  const toggle = () => set(!value);
  return [value, toggle, set];
};
