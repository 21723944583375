import React from 'react';
import { Box, Chip } from '@material-ui/core';
import makeThemedStyles from '../Theme/makeThemedStyles';
import { getLabelStyle } from '../../utils/colours';
import { LabelKeys, LabelTitles } from '../../data/types';

const useStyles = makeThemedStyles(theme => ({
  box: {
    minHeight: 5,
    // margin: theme.spacing(0, 1),
  },
  chip: {
    flexGrow: 1,
    margin: theme.spacing(0.25),
    '& .MuiCollapse-wrapperInner': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

interface Props {
  labels: LabelKeys[];
  titles: LabelTitles;
}

const LabelBar = ({ labels, titles }: Props) => {
  const classes = useStyles();

  const isLabelShown = labels.length <= 2;

  const getLabel = key => (isLabelShown ? titles[key] : undefined);

  const getStyles = key => ({
    ...getLabelStyle()(key),
    maxWidth: 100 / labels.length + '%',
  });

  return (
    <Box display="flex" justifyContent="space-evenly" className={classes.box}>
      {labels.map(key => (
        <Chip key={key} size="small" label={getLabel(key)} className={classes.chip} style={getStyles(key)}></Chip>
      ))}
    </Box>
  );
};

export default React.memo(LabelBar);
