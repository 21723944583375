import React from 'react';
import { useHistory } from 'react-router-dom';
import { List, ListItemSecondaryAction, IconButton, Box, Button } from '@material-ui/core';
import { Delete as DeleteIcon, ArrowDownward as ArrowDownIcon, ArrowUpward as ArrowUpIcon, Add as AddIcon, GetApp as DownloadIcon } from '@material-ui/icons';

import { Domain } from '../../data/types';
import { cloneDomains } from '../../utils/clone';
import { getEmptyDomain } from '../../data/empty';

import { useAppContext } from '../../data/AppContext';
import { BookIcon } from '../../components/Icons/Book';
import ListItemLink from '../../components/ListItemLink/ListItemLink';

const getDomainUrl = domain => `/domain/${domain.id}`;

const DomainsPage = () => {
  const { appData, setDomain, setDomains } = useAppContext();
  const history = useHistory();

  const domains = appData.domains;

  const gotoDomain = (domain: Domain) => history.push(getDomainUrl(domain));

  const move = (domain: Domain, dir: number) => () => {
    const index = domains.indexOf(domain);
    const newOrder = cloneDomains(domains);
    newOrder.splice(index + dir, 0, newOrder.splice(index, 1)[0]);
    setDomains(newOrder);
  };
  const remove = (domain: Domain) => () => {
    const index = domains.indexOf(domain);
    const newDomains = cloneDomains(domains);
    newDomains.splice(index, 1);
    setDomains(newDomains);
  };
  const add = () => {
    const newDomain = getEmptyDomain();
    newDomain.title = 'New Domain';
    setDomain(newDomain);
    gotoDomain(newDomain);
  };

  return (
    <div>
      <List>
        {domains.map((domain, index) => (
          <ListItemLink key={domain.id} to={getDomainUrl(domain)} primary={domain.title || domain.id} secondary={domain.subtitle} icon={<BookIcon />}>
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={move(domain, 1)} disabled={index === domains.length - 1}>
                <ArrowDownIcon />
              </IconButton>
              <IconButton edge="end" onClick={move(domain, -1)} disabled={index === 0}>
                <ArrowUpIcon />
              </IconButton>
              <IconButton edge="end" onClick={remove(domain)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItemLink>
        ))}
      </List>

      <Box display="flex" justifyContent="space-evenly">
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={add}>
          New Domain
        </Button>
      </Box>
    </div>
  );
};

export default DomainsPage;
