import React from 'react';

const OldManFace = ({ className }) => (
  <svg version="1.1" x="0" y="0" width="1417.323" height="1417.323" viewBox="0, 0, 1417.323, 1417.323" className={className}>
    <defs>
      <radialGradient id="Gradient_1" gradientUnits="userSpaceOnUse" cx="864" cy="942.395" r="102">
        <stop offset="0" stopColor="#000000" />
        <stop offset="1" stopColor="#000000" stopOpacity="0" />
      </radialGradient>
      <radialGradient id="Gradient_2" gradientUnits="userSpaceOnUse" cx="540" cy="942.395" r="102">
        <stop offset="0" stopColor="#000000" />
        <stop offset="1" stopColor="#000000" stopOpacity="0" />
      </radialGradient>
    </defs>
    <g id="face">
      <path
        d="M703.811,216.595 C825.169,217.554 949.246,250.891 1007.811,344.595 C1087.811,472.595 1108.045,512.609 1099.811,652.595 C1091.811,788.595 1014.866,958.325 954,1076 C894,1192 947.996,1237.115 709.406,1238.531 C462,1240 543.077,1181.296 474,1074 C391.594,946 315.811,789.854 315.811,636.595 C324.778,435.221 373.549,402.6 451.811,324.595 C530.072,246.591 582.826,218.127 703.811,216.595 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M708.661,947.661 C785.644,948.269 845.66,1008.857 846.631,1085.607 L846.631,1085.607 L846.631,1085.631 C846.631,1182.85 784.86,1261.661 708.661,1261.661 C632.463,1261.661 570.692,1182.85 570.692,1085.631 L570.692,1085.607 L570.692,1085.607 C571.306,1008.614 631.915,948.633 708.661,947.661 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M1073.977,824.395 C1038.354,824.395 1009.477,765.072 1009.477,691.895 C1009.477,618.717 1038.354,559.395 1073.977,559.395 C1109.599,559.395 1138.477,618.717 1138.477,691.895 C1138.477,765.072 1109.599,824.395 1073.977,824.395 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M356.5,824.395 C320.878,824.395 292,765.072 292,691.895 C292,618.717 320.878,559.395 356.5,559.395 C392.122,559.395 421,618.717 421,691.895 C421,765.072 392.122,824.395 356.5,824.395 z"
        fill="#FFDBAC"
        id="skin"
      />
      <path
        d="M864,1044.395 C807.667,1044.395 762,998.728 762,942.395 C762,886.061 807.667,840.395 864,840.395 C920.333,840.395 966,886.061 966,942.395 C966,998.728 920.333,1044.395 864,1044.395 z"
        fill="url(#Gradient_1)"
        id="cheeks"
        opacity="0.05"
      />
      <path
        d="M540,1044.395 C483.667,1044.395 438,998.728 438,942.395 C438,886.061 483.667,840.395 540,840.395 C596.333,840.395 642,886.061 642,942.395 C642,998.728 596.333,1044.395 540,1044.395 z"
        fill="url(#Gradient_2)"
        id="cheeks"
        opacity="0.05"
      />
    </g>
    <g id="features">
      <path d="M608.161,1039.086 L810.161,1039.086" fillOpacity="0" stroke="#000000" strokeWidth="10" id="mouth" opacity="0.2" />
      <path d="M709.161,948.161 C571.192,948.161 586.5,912.5 690.938,665.086" fillOpacity="0" stroke="#000000" strokeWidth="10" id="nose" opacity="0.2" />
      <path d="M678.938,1156.086 C692.959,1143.659 713.159,1143.859 725.161,1156.086" fillOpacity="0" stroke="#000000" strokeWidth="10" id="dimple" opacity="0.2" />
      <path d="M815.513,1066.418 C835.18,1058.418 835.846,1033.085 817.18,1018.085" fillOpacity="0" stroke="#000000" strokeWidth="10" id="wrinkle" opacity="0.2" />
      <path d="M602.167,1066.418 C582.5,1058.418 581.833,1033.085 600.5,1018.085" fillOpacity="0" stroke="#000000" strokeWidth="10" id="wrinkle" opacity="0.2" />
      <path d="M918.54,1005.561 C955.874,976.895 957.874,889.561 912.54,868.895" fillOpacity="0" stroke="#000000" strokeWidth="5" id="sunken_cheek" opacity="0.05" />
      <path d="M489.559,1004.561 C452.225,975.895 450.225,888.561 495.559,867.895" fillOpacity="0" stroke="#000000" strokeWidth="5" id="sunken_cheek" opacity="0.05" />
      <path d="M613.305,436.81 C699.305,450.81 757.167,450.81 799.305,444.143" fillOpacity="0" stroke="#000000" strokeWidth="5" id="wrinkle" opacity="0.05" />
      <path d="M536.727,456.497 C604.882,482.869 824.727,490.497 878.727,458.497" fillOpacity="0" stroke="#000000" strokeWidth="5" id="wrinkle" opacity="0.05" />
      <path d="M575.305,500.143 C647.971,520.143 770.638,524.143 834.638,503.477" fillOpacity="0" stroke="#000000" strokeWidth="5" id="wrinkle" opacity="0.05" />
    </g>
    <g id="hair_layer">
      <path
        d="M693.508,117.495 C643.315,116.161 591.218,128.161 541.17,152.161 C491.122,176.161 443.122,212.161 401.122,258.828 C348.029,317.821 325.814,402.064 317.421,480.901 C309.029,559.738 314.459,633.167 316.657,670.531 C320.657,738.531 327.866,776.688 336.475,779.267 C345.084,781.846 355.093,748.846 364.692,674.531 C372.129,616.963 377.784,544.74 407.846,490 C437.909,435.26 475.989,356 509.323,320 C523.989,340 558.134,367.74 645.323,366 C732.511,364.26 903.323,330.667 931.323,300 C951.323,334.667 1002.297,424.87 1029.323,478 C1056.348,531.13 1065.537,618.799 1061.323,664.586 C1052.12,764.586 1050.556,798.085 1065.804,792.626 C1081.051,787.167 1099.351,736.272 1102.358,654.531 C1105.628,565.654 1106.076,484.27 1095.799,414.411 C1085.523,344.553 1047.042,256.411 991.042,243.078 C987.042,185.745 908.48,149.289 852.847,133.969 C797.215,118.648 743.7,118.828 693.508,117.495 z"
        fill="#9D6214"
        id="hair"
      />
    </g>
  </svg>
);

export default React.memo(OldManFace);
